<div class="flex justify-center w-full h-64 text-bluegrey-800">
  <div class="w-full max-w-sm p-6 m-auto text-center" [ngSwitch]="status">
    <div *ngSwitchCase="'ERROR'">
      <ng-container *ngTemplateOutlet="error"></ng-container>
    </div>
    <div *ngSwitchCase="'SUCCESS'">
      <ng-container *ngTemplateOutlet="success"></ng-container>
    </div>
  </div>
</div>

<ng-template #success>
  <div class="flex justify-center w-full mb-6">
    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
      <mask height="34" id="prefix__a" maskUnits="userSpaceOnUse" width="34" x="3" y="3">
        <path
          clip-rule="evenodd"
          d="M20 3.333C10.8 3.333 3.334 10.8 3.334 20c0 9.2 7.466 16.667 16.666 16.667S36.667 29.2 36.667 20C36.667 10.8 29.2 3.333 20 3.333zm-3.333 25L8.333 20l2.35-2.35 5.984 5.967 12.65-12.65 2.35 2.366-15 15z"
          fill="#fff"
          fill-rule="evenodd"></path>
      </mask>
      <g mask="url(#prefix__a)">
        <path d="M-21.666 -21.667H61.667V61.666H-21.666z" fill="#1EE9B6"></path>
      </g>
    </svg>
  </div>
  <div class="mb-6 text-2xl">Thank you for confirming you have received this request</div>
  <div class="font-extralight">
    The patient has been notified that it may take up to 2 working days for the request to be
    processed. If there are any issues please contact the patient directly.
  </div>
</ng-template>

<ng-template #error>
  <div class="flex justify-center w-full mb-6">
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9999 3.33325C10.7999 3.33325 3.33325 10.7999 3.33325 19.9999C3.33325 29.1999 10.7999 36.6666 19.9999 36.6666C29.1999 36.6666 36.6666 29.1999 36.6666 19.9999C36.6666 10.7999 29.1999 3.33325 19.9999 3.33325ZM18.3333 28.3333V24.9999H21.6666V28.3333H18.3333ZM18.3333 11.6666V21.6666H21.6666V11.6666H18.3333Z"
        fill="#BF360C" />
    </svg>
  </div>
  <div class="mb-6 text-2xl">{{ message }}</div>
</ng-template>
