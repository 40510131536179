import { PartnerPromotion } from './partner-promotion';

export interface AllowanceConfig {
  id?: number;
  name: string;
  allowance: number;
  refreshMinutes: number;
  temporaryIncrease: number;
  lastRefreshDate: string;
}

export interface AllowancePartnerPromotions {
  partnerId: string;
  partnerName: string;
  promotionId: number;
  promotionName: string;
  appointmentAllowanceId?: number;
  temporaryIncrease?: number;
}

export interface Allowance extends AllowanceConfig {
  usedThisPeriod: number;
  partnerPromotions: AllowancePartnerPromotions[];
  nextRefreshDate?: string;
  partnerPromotion?: PartnerPromotion;
}

export function generateBlankAllowance(): AllowanceConfig {
  return {
    id: null,
    name: '',
    allowance: 0,
    refreshMinutes: 1440,
    temporaryIncrease: 0,
    lastRefreshDate: null,
  };
}
