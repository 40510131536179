<header class="c-header c-header--simple">
  <div class="c-header__wrap">
    <div class="o-wrapper o-container o-container--fluid">
      <div class="o-row o-row--no-margin-bottom middle-xs">
        <div class="col-xs-8 col-sm-4 col-lg-3">
          <pushdr-home-logo></pushdr-home-logo>
        </div>
        <div class="col-xs-4 col-sm-8 col-lg-9 end-xs">
          <nav *ngIf="user$ | async as user">
            <ul class="c-header__meganav-list">
              <ng-container *ngIf="user.role === PartnerUserRole.USER">
                <li class="c-header__meganav-list-item">
                  <a href="https://www.pushdoctor.co.uk/partners/training-hub" target="_blank"
                    >Training Hub</a
                  >
                </li>
                <li
                  *ngIf="user.partnerType === PartnerPortalPartnerType.NHS"
                  class="c-header__meganav-list-item"
                  routerLinkActive="c-header__meganav-list-item--is-active">
                  <a [routerLink]="['portal', 'nhs', user.id, 'track']">Track Patients</a>
                </li>
                <li
                  *ngIf="user.partnerType === PartnerPortalPartnerType.NHS"
                  class="c-header__meganav-list-item"
                  routerLinkActive="c-header__meganav-list-item--is-active">
                  <a [routerLink]="['portal', 'nhs', user.id, 'invite']">Invite Patients</a>
                </li>
              </ng-container>

              <ng-container *ngIf="user.role === PartnerUserRole.ADMIN">
                <li
                  class="c-header__meganav-list-item"
                  routerLinkActive="c-header__meganav-list-item--is-active">
                  <a [routerLink]="['portal', 'admin', user.id, 'search-user']">Search User</a>
                </li>
                <li
                  class="c-header__meganav-list-item"
                  routerLinkActive="c-header__meganav-list-item--is-active">
                  <a [routerLink]="['portal', 'admin', user.id, 'default-signposts']"
                    >Signposting</a
                  >
                </li>
                <li
                  class="c-header__meganav-list-item"
                  routerLinkActive="c-header__meganav-list-item--is-active">
                  <a [routerLink]="['portal', 'admin', user.id, 'partners']">Partners</a>
                </li>
                <li
                  class="c-header__meganav-list-item"
                  routerLinkActive="c-header__meganav-list-item--is-active">
                  <a [routerLink]="['portal', 'admin', user.id, 'users']">Admin users</a>
                </li>
              </ng-container>

              <pushdr-partnerportal-user-selector></pushdr-partnerportal-user-selector>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>
