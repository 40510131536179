import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiPartnerPortalService } from '@pushdr/partnerportal/common/data-access/partnerportal-api';
import { ModalService } from '@pushdr/common/overlay';
import { switchMap } from 'rxjs/operators';
import { SignInPartnerPortalService } from '../../services/sign-in/sign-in-partner-portal.service';
import { PASSWORD_LENGTH_MIN } from '@pushdr/assets';

@Component({
  selector: 'pushdr-verify-admin',
  templateUrl: './verify-admin.component.html',
  styleUrls: ['./verify-admin.component.scss'],
})
export class VerifyAdminComponent implements OnInit {
  email: string;
  mobile: string;
  code: string;
  password: string;
  PASSWORD_LENGTH_MIN = PASSWORD_LENGTH_MIN;

  constructor(
    private api: ApiPartnerPortalService,
    private route: ActivatedRoute,
    private modal: ModalService,
    private signIn: SignInPartnerPortalService
  ) {}

  ngOnInit() {
    this.getQueryParams();
  }

  getQueryParams() {
    this.route.queryParams.subscribe(params => {
      this.code = params.token;
    });
  }

  submit() {
    this.modal.showLoader({ bottomText: 'Registering' });
    this.api.account
      .verifyMobile(this.email, this.mobile, this.code, this.password)
      .pipe(switchMap(() => this.signIn.signIn(this.email, this.password)))
      .subscribe(
        () => {
          this.signIn.redirectBasedOnUserType();
          this.modal.close();
        },
        error => {
          this.modal.error(error.message);
        }
      );
  }
}
