import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartnerportalCommonComponentsModule } from '@pushdr/partnerportal/common/components';
import {
  PartnerportalAuthenticationModule,
  SignInComponent,
  ResetPasswordComponent,
  VerifyUserComponent,
  VerifyAdminComponent,
  AuthGuard,
  VerifyManagerComponent,
  QuestionnaireAcknowledgementComponent,
  IsThisUserGuard,
  AcknowledgeQuestionnaireComponent,
} from '@pushdr/partnerportal/authentication';
import { NotFoundComponent } from '@pushdr/common/components';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: SignInComponent,
  },
  {
    path: 'QuestionnaireAcknowledgement/:reference/:hash',
    component: QuestionnaireAcknowledgementComponent,
  },
  {
    path: 'acknowledge',
    children: [{ path: 'questionnaire', component: AcknowledgeQuestionnaireComponent }],
  },
  {
    path: 'verify',
    children: [
      {
        path: '',
        component: VerifyUserComponent,
      },
      {
        path: 'admin',
        component: VerifyAdminComponent,
      },
      {
        path: 'manager',
        component: VerifyManagerComponent,
      },
      {
        path: 'resetpassword',
        component: ResetPasswordComponent,
      },
    ],
  },
  {
    path: 'portal',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'nhs/:id',
        canActivate: [IsThisUserGuard],
        loadChildren: () =>
          import('@pushdr/partnerportal/clinical-navigator').then(
            m => m.PartnerportalClinicalNavigatorModule
          ),
      },
      {
        path: 'admin/:id',
        canActivate: [IsThisUserGuard],
        loadChildren: () =>
          import('@pushdr/partnerportal/admin/system').then(m => m.PartnerportalAdminSystemModule),
      },
      {
        path: 'manager/:id',
        canActivate: [IsThisUserGuard],
        loadChildren: () =>
          import('@pushdr/partnerportal/admin/partner').then(
            m => m.PartnerportalAdminPartnerModule
          ),
      },
    ],
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [
    PartnerportalCommonComponentsModule,
    PartnerportalAuthenticationModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class PartnerPortalAppRoutingModule {}
