import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { Allowance, AllowanceConfig } from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalAllowanceService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/allowance';
  }

  getAllowances() {
    return this.get<Allowance[]>('/', {}, this.headerService.bearerTokenHeaders());
  }

  getAllowance(id: number, partnerId: number) {
    return this.get<Allowance>(
      `/${id}?partnerId=${partnerId}`,
      {},
      this.headerService.bearerTokenHeaders()
    );
  }

  putAllowance(allowance: AllowanceConfig, partnerId: string) {
    return this.put<string>(
      `/${allowance.id}`,
      { ...allowance, partnerId },
      this.headerService.bearerTokenHeaders()
    );
  }

  createAllowance(allowance: AllowanceConfig) {
    return this.post<number>('/createnew', allowance, this.headerService.bearerTokenHeaders());
  }

  allowanceAddNhsPartner(allowanceid: number, partnerId: string) {
    return this.put<string>(
      `/${allowanceid}/addNhsPartner`,
      { partnerId },
      this.headerService.bearerTokenHeaders()
    );
  }
}
