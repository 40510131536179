<div *ngIf="user" class="c-modal__wrap" cdkTrapFocus="ready$ | async">
  <form novalidate #f="ngForm" (ngSubmit)="f.valid && add()" class="c-form-part__form">
    <div class="modal__body">
      <h3 class="c-modal__title">Add partner user</h3>
      <div *ngIf="showUserType" class="c-form-part__margin-wrap input-radio-group">
        <label for="user_type">
          <span class="block-label">User type</span>
          <label
            tabindex="0"
            for="user"
            class="input-radio-label"
            #defaultFocus
            (keyup.space)="user.role = PartnerUserRole.USER"
            [class.checked]="user.role === PartnerUserRole.USER">
            <input
              id="user"
              type="radio"
              name="delivery"
              (click)="user.role = PartnerUserRole.USER"
              [checked]="user.role === PartnerUserRole.USER" />
            <span>User</span>
          </label>
          <label
            tabindex="0"
            for="user_admin"
            class="input-radio-label"
            (keyup.space)="user.role = PartnerUserRole.MANAGER"
            [class.checked]="user.role === PartnerUserRole.MANAGER">
            <input
              id="user_admin"
              type="radio"
              name="delivery"
              (click)="user.role = PartnerUserRole.MANAGER"
              [checked]="user.role === PartnerUserRole.MANAGER" />
            <span>Manager</span>
          </label>
        </label>
      </div>
      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="email.invalid && f.submitted">
        <label for="ipt-email-or-mobile">Email address</label>
        <input
          [(ngModel)]="user.email"
          #email="ngModel"
          name="email"
          class="c-form-part__field"
          id="ipt-email-or-mobile"
          required
          aria-required="true"
          type="text"
          required />
        <p
          class="c-form-part__error-message"
          id="err-msg-email-required"
          *ngIf="email?.errors?.required">
          An email is required
        </p>
      </div>
      <div
        *ngIf="user.role === PartnerUserRole.MANAGER"
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="mobile.invalid && f.submitted">
        <label for="ipt-mobile-or-mobile">UK mobile number</label>
        <input
          [(ngModel)]="user.mobile"
          #mobile="ngModel"
          name="mobile"
          class="c-form-part__field"
          id="ipt-mobile-or-mobile"
          [required]="user.role === PartnerUserRole.MANAGER"
          aria-required="true"
          type="text"
          required
          [pattern]="ukMobileRegexPattern"
          type="tel"
          autocomplete="tel" />
        <p
          class="c-form-part__error-message"
          id="err-msg-mobile-required"
          *ngIf="mobile?.errors?.required || mobile?.errors?.pattern">
          A valid UK mobile is required
        </p>
      </div>
    </div>
    <div class="modal__footer">
      <button class="c-btn c-btn--primary" value="Add user" type="submit">Add</button>
      <button class="c-btn c-btn--secondary" value="Cancel" type="button" (click)="cancel()">
        Cancel
      </button>
    </div>
  </form>
</div>
