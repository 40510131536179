import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { RestNoCacheInterceptorService } from '@pushdr/common/data-access/rest-http-core';
import {
  EnvironmentProxyService,
  StorageService,
  TokenModule,
  WINDOW_PROVIDER,
} from '@pushdr/common/utils';
import { PartnerportalCommonComponentsModule } from '@pushdr/partnerportal/common/components';
import { environment } from '../environments/environment';
import { PartnerPortalAppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { PartnerPortalAnalyticsService } from '@pushdr/partnerportal/common/utils';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import { AlertBarModule } from '@pushdr/common/components';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonOverlayModule,
    BrowserAnimationsModule,
    PartnerPortalAppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PartnerportalCommonComponentsModule,
    SpinnerLoaderModule,
    AlertBarModule,
    TokenModule.forRoot({ tokenName: 'partner_token', useHostName: true }),
  ],
  providers: [
    WINDOW_PROVIDER,
    { provide: HTTP_INTERCEPTORS, useClass: RestNoCacheInterceptorService, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: EnvironmentProxyService,
      useValue: new EnvironmentProxyService().setup(environment),
    },
    {
      provide: AnalyticsService,
      useClass: PartnerPortalAnalyticsService,
    },
    StorageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
