<ng-container *ngIf="!forgotPassword; else showForgotPassword">
  <div class="c-modal__wrap">
    <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
      <div class="modal__body">
        <h2 class="c-modal__title">Sign in</h2>
        <div
          class="c-form-part__field-wrap"
          [class.c-form-part__entry--is-invalid]="email.invalid && f.submitted">
          <label for="ipt-email-or-mobile">Email address</label>
          <input
            [(ngModel)]="username"
            #email="ngModel"
            name="email"
            class="c-form-part__field"
            id="ipt-email-or-mobile"
            pattern="[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*"
            required
            aria-required="true"
            type="text"
            required
            trimOnBlur />
          <p
            class="c-form-part__error-message"
            id="err-msg-email-required"
            *ngIf="email?.errors?.required"
            role="alert">
            An email is required
          </p>
          <p
            class="c-form-part__error-message"
            id="err-msg-pattern"
            *ngIf="email?.errors?.pattern"
            role="alert">
            Please use a valid email format e.g. name@domain.com
          </p>
        </div>

        <div
          class="c-form-part__field-wrap"
          [class.c-form-part__entry--is-invalid]="pw.invalid && f.submitted">
          <label class="c-form-part__field-label" for="ipt-password">Password</label>
          <input
            [(ngModel)]="password"
            name="Password"
            #pw="ngModel"
            required
            class="c-form-part__field"
            id="ipt-password"
            aria-required="true"
            type="password" />
          <p
            class="c-form-part__error-message"
            id="err-msg-password-required"
            *ngIf="pw?.errors?.required">
            A password is required
          </p>
          <button
            class="c-btn-anchor u-mt16@xs"
            type="button"
            (click)="forgotPassword = !forgotPassword">
            Forgot password?
          </button>
        </div>
      </div>
      <div class="modal__footer">
        <label
          ><input
            type="checkbox"
            [(ngModel)]="persistToken"
            name="persistToken"
            id="persistToken" />&nbsp;Keep all accounts signed in
          <a
            href="javascript: void(0)"
            title="You will remain signed in for 24 hours, do not tick this if you are on a shared terminal"
            >[?]</a
          >
        </label>
        <button
          class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin u-mb16@xs"
          value="Sign in">
          Sign in
        </button>
      </div>
    </form>
  </div>
</ng-container>

<ng-template #showForgotPassword>
  <div class="c-modal__wrap">
    <form novalidate #f="ngForm" (ngSubmit)="f.valid && resetPassword()" class="c-form-part__form">
      <div class="modal__body">
        <h2 class="c-modal__title">Reset password</h2>
        <div
          class="c-form-part__field-wrap"
          [class.c-form-part__entry--is-invalid]="email.invalid && f.submitted">
          <label>Email address</label>
          <input
            [(ngModel)]="username"
            #email="ngModel"
            name="email"
            class="c-form-part__field"
            pattern="[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*"
            required
            aria-required="true"
            type="text"
            required />
          <p class="c-form-part__error-message" *ngIf="email?.errors?.required" role="alert">
            An email is required
          </p>
          <p class="c-form-part__error-message" *ngIf="email?.errors?.pattern" role="alert">
            Please use a valid email format e.g. name@domain.com
          </p>
        </div>
      </div>
      <div class="modal__footer">
        <button
          class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin u-mb16@xs">
          Send reset link
        </button>
      </div>
    </form>
    <div class="u-text-center">
      <button class="c-btn-anchor u-mt16@xs" (click)="forgotPassword = false" value="Cancel">
        Return to sign in
      </button>
    </div>
  </div>
</ng-template>
