import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalCommunicationService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/communication/';
  }

  sendSMS(number: string, BodyText: string) {
    return this.post(
      'sms',
      {
        Numbers: [number],
        BodyText,
      },
      this.headerService.bearerTokenHeaders(),
      4
    );
  }
}
