import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pushdr-generic-card',
  templateUrl: './generic-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericCardComponent {
  @Input() title: string;
  @Input() hasData = true;
  @Input() customStyles: { [key: string]: any };
}
