export enum SurgeryType {
  PRIVATE = 2,
  TEST = 3,
  EMIS = 4,
  TPP = 5,
  HYBRID = 7,
  PDR = 8, // new private
}

export enum SelectableSurgeryTypes {
  EMIS = 4,
  TPP = 5,
}

export enum SelectableCorporateSurgeryTypes {
  PDR = 8,
}

export interface Surgery {
  add1: string;
  add2: string;
  add3: string;
  add4: string;
  id: number;
  lat: number;
  linkedCustomers: number;
  lon: number;
  name: string;
  odscode: string;
  partnershipType: number;
  postCode: string;
  promotionClientId: number;
  surgeryTypeId: number;
  tel: string;
}
