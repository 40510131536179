import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AbstractModal } from '@pushdr/common/overlay';

export interface IframeModal {
  title: string;
  linkToDocument: string;
  dateCreated?: string;
  callToAction?: string;
  cancelText?: string;
}

@Component({
  selector: 'pushdr-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss'],
})
export class IframeModalComponent extends AbstractModal<IframeModal> implements OnInit {
  safeUrl: SafeResourceUrl;
  callToAction: string;
  cancelText: string;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    this.cancelText = this.data.cancelText || 'Close';
    this.callToAction = this.data.callToAction;
    this.sanitizeUrl();
  }

  output() {
    this.close();
  }

  private sanitizeUrl() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.linkToDocument);
  }
}
