import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiWebHooksService } from '@pushdr/common/data-access/web-hooks';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

export enum QuestionnaireStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

@Component({
  selector: 'pushdr-track',
  templateUrl: './acknowledge-questionnaire.component.html',
})
export class AcknowledgeQuestionnaireComponent implements OnInit {
  status$: Observable<QuestionnaireStatus>;
  message = '';

  constructor(private route: ActivatedRoute, private api: ApiWebHooksService) {}

  ngOnInit() {
    this.status$ = this.route.queryParams.pipe(
      switchMap(params => this.api.markQuestionnaireAsRead(params.id, params.hash)),
      map(() => QuestionnaireStatus.SUCCESS),
      catchError(err => {
        this.message = err.message || 'Something went wrong.';
        return of(QuestionnaireStatus.ERROR);
      })
    );
  }
}
