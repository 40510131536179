import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Surgery } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-surgery-list-item',
  templateUrl: './surgery-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurgeryListItemComponent {
  @Input() surgery: Surgery;
}
