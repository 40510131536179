import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapKeyPipe } from './map-key.pipe';
import { PartnerTypePipe } from './partner-type.pipe';
import { SurgeryTypeTextHumanizedPipe } from './surgery-type-text-humanized.pipe';
import { SurgeryTypePipe } from './surgery-type.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [MapKeyPipe, PartnerTypePipe, SurgeryTypeTextHumanizedPipe, SurgeryTypePipe],
  exports: [MapKeyPipe, PartnerTypePipe, SurgeryTypeTextHumanizedPipe, SurgeryTypePipe],
})
export class PartnerportalCommonPipesModule {}
