import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { InviteBookingCount, PatientBooking } from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { map, pluck } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalBookingService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/booking';
  }

  getBookings() {
    return this.get<PatientBooking[]>('', {}, this.headerService.bearerTokenHeaders(), 3.5);
  }

  // used for counting
  PartnerBookingCountByDate(dateFrom, dateTo) {
    const queryParams = { dateFrom, dateTo } as any;
    return this.get<InviteBookingCount>(
      '/count',
      queryParams,
      this.headerService.bearerTokenHeaders(),
      3.5
    ).pipe(pluck('bookingCount'));
  }

  getTotalPartnerBookingCountsToday() {
    const start = new Date();
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setHours(23, 59, 59, 999);

    return this.PartnerBookingCountByDate(start.toISOString(), end.toISOString()).pipe(
      map(res => this.mapToDefaultIfUndefined(res))
    );
  }

  getTotalPartnerBookingCountsTomorrowOnwards() {
    const today = new Date();
    const start = new Date();
    start.setDate(today.getDate() + 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setDate(today.getDate() + 30);
    end.setHours(23, 59, 59, 999);

    return this.PartnerBookingCountByDate(start.toISOString(), end.toISOString()).pipe(
      map(res => this.mapToDefaultIfUndefined(res))
    );
  }

  private mapToDefaultIfUndefined(res): InviteBookingCount {
    res.Cancelled = res.Cancelled || 0;
    res.Completed = res.Completed || 0;
    res.InProgress = 0; // deprecated remove at when we can
    res.Missed = res.Missed || 0;
    res.None = res.None || 0;
    res.Scheduled = res.Booked || 0;
    return res;
  }
}
