import { Environment } from './utils/environment-interface';

const partnerPortal = 'https://sitpartnerportal.startfeelingbetter.com';
const portal = 'http://sitportal.startfeelingbetter.com';

// prettier-ignore
export const environment: Environment = {
  id: 'sit',
  version: 'localhost',
  webHooksAPI: 'https://sit-waf.internal.pushsvcs.com/hook/api',
  homepage: 'https://www.pushdoctor.co.uk',
  partner: {
    api: 'https://sit-waf.internal.pushsvcs.com/partner/api/v1',
    admin: partnerPortal + '/portal/nhs/?/invite',
    cn: partnerPortal + '/portal/admin/?/partners'
  },
  portal: {
    api: 'https://sit-waf.internal.pushsvcs.com/ops/api',
    url: portal,
    signin: portal + '/auth/signin',
    apiWithJWT: 'https://sit-waf.internal.pushsvcs.com/portal/v0',
  },
  pdrportal: {
    url: 'https://sit.startfeelingbetter.com'
  },
  production: false,
  appInsights: {
    config: {
      instrumentationKey: '4acb6464-d86d-4d5f-97f0-9863826ef18a'
    }
  },
  google: {
    portalGtm: 'GTM-MW5C432',
  },
};
