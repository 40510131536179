import { Injectable } from '@angular/core';
import {
  AnalyticsBusService,
  InsightsService,
  AnalyticsService,
  AnalyticsUser,
} from '@pushdr/common/data-access/analytics';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { SignInPartnerPortalService } from '@pushdr/partnerportal/authentication';
import { ErrorLogsService, GTMEventTrackingService } from '@pushdr/common/data-access/analytics';

@Injectable({
  providedIn: 'root',
})
export class PartnerPortalAnalyticsService extends AnalyticsService {
  private _initialised = false;

  constructor(
    protected bus: AnalyticsBusService,
    private env: EnvironmentProxyService,
    private insights: InsightsService,
    private errorLogs: ErrorLogsService,
    private auth: SignInPartnerPortalService,
    private gtm: GTMEventTrackingService
  ) {
    super(bus);
  }

  initialise(appIdentifier: string) {
    if (!this._initialised) {
      this._initialised = true;
      this.insights.initialise(
        { user: [''], events: ['view', 'click', 'router', 'invite_patient', 'log', 'network'] },
        this.env.environment.appInsights,
        appIdentifier
      );
      this.gtm.initialise(
        this.env.environment.google.portalGtm,
        {
          user: [''],
          events: ['userType', 'view', 'click', 'portal_sms'],
        },
        'PartnerPortal'
      );
      this.auth.user$.subscribe(user => this.setUser(new AnalyticsUser(user)));
      this.errorLogs.initialise({ user: [''], events: ['router', 'log', 'error', 'network'] });
    } else {
      throw Error('Analytics service has already initialised');
    }
  }
}
