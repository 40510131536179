<div class="c-modal__wrap" cdkTrapFocus="ready$ | async">
  <div class="modal__body">
    <h3 class="c-modal__title">Delete this user?</h3>
    <table class="pp-table">
      <tbody>
        <tr *ngIf="userType">
          <td>Type</td>
          <td>{{ userType }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>
            {{ email }}
            <span *ngIf="emailConfirmed === false" title="User has not confirmed email">(?)</span>
          </td>
        </tr>
        <tr *ngIf="mobile">
          <td>Mobile</td>
          <td>
            {{ mobile }}
            <span *ngIf="mobileConfirmed === false" title="User has not confirmed mobile number"
              >(?)</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal__footer">
    <button class="c-btn c-btn--delete" (click)="closeWithResult(true)">Delete</button>
    <button class="c-btn c-btn--secondary" (click)="closeWithResult(false)">Cancel</button>
  </div>
</div>
