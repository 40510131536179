import { Injectable } from '@angular/core';
import { StorageService } from '@pushdr/common/utils';
import { AnalyticsBusService } from '../analytics-bus/analytics-bus.service';
import { AnalyticsEvent } from '../analytics-types';

@Injectable({
  providedIn: 'root',
})
export class CurrentOrderIdService {
  private currentOrderIdKey = 'orderid';
  private order = 0;

  constructor(private storage: StorageService, private analytics: AnalyticsBusService) {}

  get id(): number {
    const id = this.order || this.restoreIdFromStorage();
    this.analytics.trackEvent(AnalyticsEvent.info('get OrderId:', 'OrderId: ' + id));
    return id;
  }

  set id(id: number) {
    this.analytics.trackEvent(AnalyticsEvent.info('set OrderId:', 'SetOrderId: ' + id));
    this.storeId(id);
  }

  flush() {
    this.storeId(0);
  }

  private restoreIdFromStorage(): number {
    this.order = parseInt(this.storage.getSessionStorage(this.currentOrderIdKey, false), 10);
    if (isNaN(this.order)) {
      this.order = 0;
    }
    return this.order;
  }

  private storeId(id: number) {
    this.order = id;
    this.storage.setSessionStorage(this.currentOrderIdKey, id);
  }
}
