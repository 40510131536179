import { Injectable } from '@angular/core';
import {
  ApiPartnerPortalOrganisationService,
  ApiPartnerInfoService,
  ApiPartnerServiceTimesService,
  ApiPartnerServiceTimeExceptionsService,
  ApiPartnerPortalAccountService,
  ApiPartnerPortalAdminUserService,
  ApiPartnerPortalEnumsService,
  ApiPartnerPortalInviteService,
  ApiPartnerPortalPromotionsService,
  ApiPartnerPortalPartnerUserService,
  ApiPartnerPortalPartnerService,
  ApiPartnerPortalPartnerPatientsService,
  ApiPartnerPortalAllowanceService,
  ApiPartnerPortalBookingService,
  ApiPartnerPortalCommonService,
  ApiPartnerPortalCommunicationService,
} from './endpoints';
import { ApiPartnerPortalAvailabilityService } from './endpoints/partner-portal-availability-api.service';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalService {
  constructor(
    public account: ApiPartnerPortalAccountService,
    public availability: ApiPartnerPortalAvailabilityService,
    public booking: ApiPartnerPortalBookingService,
    public adminUser: ApiPartnerPortalAdminUserService,
    public enums: ApiPartnerPortalEnumsService,
    public invite: ApiPartnerPortalInviteService,
    public partner: ApiPartnerPortalPartnerService,
    public partnerPatients: ApiPartnerPortalPartnerPatientsService,
    public partnerUser: ApiPartnerPortalPartnerUserService,
    public promotions: ApiPartnerPortalPromotionsService,
    public serviceTimes: ApiPartnerServiceTimesService,
    public info: ApiPartnerInfoService,
    public serviceTimeExceptions: ApiPartnerServiceTimeExceptionsService,
    public organisation: ApiPartnerPortalOrganisationService,
    public allowance: ApiPartnerPortalAllowanceService,
    public common: ApiPartnerPortalCommonService,
    public communication: ApiPartnerPortalCommunicationService
  ) {}
}
