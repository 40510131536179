export interface PartnerServiceHours {
  id?: number;
  partnerId: string;
  openingDayType: OpeningDayType;
  startTime: string;
  endTime: string;
}

export enum OpeningDayType {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  BANKHOLIDAY,
}

export const OpenDayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Bank Holidays',
];

export interface PartnerServiceTimeException {
  id?: number;
  partnerId: string; //uuid
  date: string; // utc isostring
  startTime: string; // hh:mm:ss
  endTime: string; // hh:mm:ss
}
