import { PartnerType } from '../nhs';
import { SurgeryType } from './gp';

export enum TriageSetting {
  TRIAGE_OFF,
  TRIAGE_ON,
}

export enum InviteSetting {
  NO_INVITE_REQUIRED,
  INVITE_ALWAYS_REQUIRED,
  INVITE_REQUIRED_FOR_FIRST_APPT,
  ACCESS_QUESTIONNAIRE_REQUIRED,
}

export interface GpDetails {
  id: number;
  name: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  postCode: string;
  odsCode: string;
  surgeryType: string;
  surgeryTypeId: SurgeryType;
  numberOfPatients: number;
}

export interface OrganisationDetails {
  id: number;
  organisationName?: string;
  name?: string;
}

export interface Organisation {
  id: number;
  name: string;
}

export interface Partner {
  id?: string;
  name: string;
  partnerType: PartnerType;
  userAccountDomain: string;
  todayOnly: boolean;
  isLive: boolean;
  liveDate: string;
  triageSetting: TriageSetting;
  liveSurgeryTypeId: SurgeryType;
  organisation: OrganisationDetails;
  gp: GpDetails;
  appointmentAllowances: AppointmentAllowance[];
  appointmentInviteType: InviteSetting;
  translatorService: TranslatorServiceConfig;
  accessQuestionnaireEmailAddress: string;
  consultationRecording: boolean;
  isMultipleAppointmentEnabled: boolean;
}

export interface AppointmentAllowance {
  id: number;
  promotionId: number;
  partnerId: string;
  name: string;
}

export interface TranslatorServiceConfig {
  isEnabled: boolean;
  accessCode: string;
  phoneNumber: string;
}

export interface CreatePartner {
  name: string;
  organisationId: number;
}

export interface CreatePartnerNHS extends CreatePartner {
  gpId: number;
  liveDate: string;
  liveSurgeryTypeId: SurgeryType;
  todayOnly: boolean;
  triageSetting: number;
}

export interface UpdatePartnerNHS extends Partial<CreatePartner> {
  id: string;
  gpId?: number;
  liveDate?: string;
  liveSurgeryTypeId?: SurgeryType;
  todayOnly?: boolean;
  translatorService?: TranslatorServiceConfig;
  triageSetting?: number;
  appointmentInviteType?: InviteSetting;
  accessQuestionnaireEmailAddress?: string;
  clinicianType?: ClinicianType;
  partnerType?: PartnerType;
  consultationRecording?: boolean;
  isMultipleAppointmentEnabled?: boolean;
}

export enum ClinicianType {
  Unknown = 0,
  GeneralPractitioner = 1,
  MinorAilmentsPharmacist = 2,
  Physio = 3,
  MentalHealthTherapist = 4,
}

export interface PartnerPage {
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  currentPage: number;
  partners: ListPartner[];
}

export interface ListPartner {
  id: string;
  organisationID: number;
  organisationName: string;
  surgeryType: string;
  gpName: string;
  name: string;
  isLive: boolean;
  partnerType: PartnerType;
  clinicianType: ClinicianType;
  activePromotion: boolean;
}

export interface PartnerEpsDetails {
  partnerId: string;
  epsEnabled: boolean;
  representativeGpName: string;
  controllingOrganisationName: string;
  controllingOrganisationType: string;
  controllingOrganisationOdsCode: string;
  representativeGpTelephone: string;
  representativeGpSdsRoleProfileId: string;
  representativeGpSdsRoleCode: string;
  representativeGpSdsIdentifier: string;
}

export interface PartnerRelation {
  id: string;
  name: string;
  clinicianType: ClinicianType;
}

export interface PartnerRelationList {
  relationList: PartnerRelation[];
}
