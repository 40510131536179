import { Pipe, PipeTransform } from '@angular/core';
import { PartnerType } from '@pushdr/common/types';

@Pipe({
  name: 'partnerType',
  pure: true,
})
export class PartnerTypePipe implements PipeTransform {
  transform(type: PartnerType, ...args: any[]): string {
    switch (type) {
      case PartnerType.NHS:
        return ' NHS';
      case PartnerType.CORPORATE:
        return 'Corporate';
      case PartnerType.PDR:
        return 'Private';
      default:
        return 'Unsupported';
    }
  }
}
