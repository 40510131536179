import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { OrganisationDetails } from '@pushdr/common/types';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalOrganisationService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/organisation';
  }

  getAll() {
    return this.get('/', {}, this.headerService.bearerTokenHeaders());
  }

  create(name: string) {
    return this.post('/', { name }, this.headerService.bearerTokenHeaders());
  }

  getById(id: string) {
    return this.get('/' + id, {}, this.headerService.bearerTokenHeaders());
  }

  edit(organisation: OrganisationDetails) {
    return this.get('/' + organisation.id, organisation, this.headerService.bearerTokenHeaders());
  }
}
