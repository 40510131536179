import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { PartnerUserRole } from '@pushdr/partnerportal/common/types';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SignInPartnerPortalService,
  SignInUser,
} from '../../services/sign-in/sign-in-partner-portal.service';

@Component({
  selector: 'pushdr-partnerportal-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSelectorComponent implements OnInit {
  otherUsers$: Observable<SignInUser[]>;
  currentUser$: Observable<SignInUser>;
  open = false;

  constructor(
    private users: SignInPartnerPortalService,
    private router: Router,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.otherUsers$ = combineLatest(this.users.userList$, this.users.user$).pipe(
      map(([userList, user]) => {
        return userList.filter(listUser => listUser.id !== user.id);
      }),
      map(list => {
        return list.sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0));
      })
    );
    this.currentUser$ = this.users.user$;
  }

  userIcon(user: SignInUser) {
    switch (user.role) {
      case PartnerUserRole.ADMIN:
        return 'settings';
      case PartnerUserRole.MANAGER:
        return 'assignment';
      case PartnerUserRole.USER:
        return 'person_add';
    }
  }

  userLabel(user: SignInUser, showRole: boolean = true) {
    return this.users.getUserName(user, showRole);
  }

  @HostListener('window:click')
  onWindowClick() {
    this.open = false;
  }

  @HostListener('document:keydown.escape')
  onEscapePressed() {
    this.open = false;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.open) {
      event.stopPropagation();
    }
  }

  addAnother() {
    this.open = false;
    this.router.navigate(['login']);
  }

  selectUser(user: SignInUser = this.users.currentUser) {
    this.open = false;
    this.users.currentUser = user;
    this.users.redirectBasedOnUserType();
  }

  signOutAll() {
    this.modal
      .confirm(
        'Please confirm',
        'Are you sure you want to sign out of all accounts?',
        'Yes, sign out of everything',
        'Cancel'
      )
      .subscribe(res => {
        if (res) {
          this.open = false;
          this.users.signOutAll();
          this.users.redirectBasedOnUserType();
        }
      });
  }

  signOut() {
    this.open = false;
    this.users.signOut();
    this.users.redirectBasedOnUserType();
  }
}
