<div class="c-modal__wrap">
  <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
    <div class="modal__body">
      <h2 class="c-modal__title">Register</h2>
      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="vemail.invalid && f.submitted">
        <label for="ipt-email">Email address</label>
        <input
          [(ngModel)]="email"
          #vemail="ngModel"
          name="email"
          class="c-form-part__field"
          id="ipt-email"
          required
          aria-required="true"
          type="text" />
        <p
          class="c-form-part__error-message"
          id="err-msg-email-required"
          *ngIf="vemail?.errors?.required">
          An email is required
        </p>
      </div>

      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="vmobile.invalid && f.submitted">
        <label for="ipt-mobile">UK mobile number</label>
        <input
          [(ngModel)]="mobile"
          #vmobile="ngModel"
          name="mobile"
          class="c-form-part__field"
          id="ipt-mobile"
          required
          aria-required="true"
          type="text" />
        <p
          class="c-form-part__error-message"
          id="err-msg-mobile-required"
          *ngIf="vmobile?.errors?.required">
          A UK mobile number is required
        </p>
      </div>

      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="vpw.invalid && f.submitted">
        <label class="c-form-part__field-label" for="ipt-password">Set Password</label>
        <input
          [(ngModel)]="password"
          name="Password"
          #vpw="ngModel"
          required
          class="c-form-part__field"
          id="ipt-password"
          aria-required="true"
          type="password" />
        <p
          class="c-form-part__error-message"
          id="err-msg-password-required"
          *ngIf="vpw?.errors?.required">
          A password is required
        </p>
        <p
          class="c-form-part__error-message"
          id="err-msg-password-minlength"
          *ngIf="password?.length < PASSWORD_LENGTH_MIN">
          Minimum of {{ PASSWORD_LENGTH_MIN }} characters
        </p>
      </div>
    </div>
    <div class="modal__footer">
      <button
        class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin u-mb16@xs"
        value="Register">
        Register
      </button>
    </div>
  </form>
</div>
