import { take, tap } from 'rxjs/operators';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestErrorParserService } from './rest-error-parser.service';
import { RestHttpHeaderService } from './rest-header.service';

const DEFAULT_API_VERSION = 1;

@Injectable({ providedIn: 'root' })
export class RestClient {
  private _tempHeaders: HttpHeaders = null;

  protected versioning = true;

  constructor(
    protected http: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParser: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {}

  protected endpoint() {
    return '';
  }

  protected post<T = any>(
    method: string = '',
    body = {},
    headersPassed: HttpHeaders = this.headerService.unauthorisedHeaders(),
    version = DEFAULT_API_VERSION
  ): Observable<T> {
    const headers = this.tempHeaders || headersPassed;
    const path = this.buildPath(method, version);
    return this.errorParser.pipeErrorHandlers(
      this.http.post(path, body, { headers }).pipe(take(1)),
      method
    ) as Observable<T>;
  }

  protected upload<T = any>(
    method: string = '',
    file: File,
    passedHeaders: HttpHeaders = this.headerService.unauthorisedHeaders(),
    version = DEFAULT_API_VERSION
  ): Observable<T> {
    const headers = this.tempHeaders || passedHeaders;
    const formData: FormData = new FormData();
    const path = this.buildPath(method, version);
    formData.append('name', file, file.name);
    return this.errorParser.pipeErrorHandlers(
      this.http.post(path, formData, {
        headers,
        reportProgress: true,
        observe: 'events',
      }),
      method
    ) as Observable<T>;
  }

  protected put<T = any>(
    method: string = '',
    body = {},
    passedHeaders: HttpHeaders = this.headerService.unauthorisedHeaders(),
    version = DEFAULT_API_VERSION
  ): Observable<T> {
    const headers = this.tempHeaders || passedHeaders;
    const path = this.buildPath(method, version);
    return this.errorParser.pipeErrorHandlers(
      this.http.put(path, body, { headers }).pipe(take(1)),
      method
    ) as Observable<T>;
  }

  protected patch<T = any>(
    method: string = '',
    body = {},
    passedHeaders: HttpHeaders = this.headerService.unauthorisedHeaders(),
    version = DEFAULT_API_VERSION
  ): Observable<T> {
    const headers = this.tempHeaders || passedHeaders;
    const path = this.buildPath(method, version);
    return this.errorParser.pipeErrorHandlers(
      this.http.patch(path, body, { headers }).pipe(take(1)),
      method
    ) as Observable<T>;
  }

  protected get<T = any>(
    method: string = '',
    params = {},
    passedHeaders: HttpHeaders = this.headerService.unauthorisedHeaders(),
    version = DEFAULT_API_VERSION,
    options?: {
      observe?: any;
      reportProgress?: boolean;
      responseType?: any;
      withCredentials?: boolean;
    }
  ): Observable<T> {
    const headers = this.tempHeaders || passedHeaders;
    const path = this.buildPath(method, version);
    return this.errorParser.pipeErrorHandlers(
      this.http.get<T>(path, { ...options, headers, params }).pipe(take(1)),
      method
    ) as Observable<T>;
  }

  protected delete<T = any>(
    method: string = '',
    body = {},
    passedHeaders: HttpHeaders = this.headerService.unauthorisedHeaders(),
    version = DEFAULT_API_VERSION
  ): Observable<T> {
    const headers = this.tempHeaders || passedHeaders;
    const path = this.buildPath(method, version);
    return this.errorParser.pipeErrorHandlers(
      this.http.request('delete', path, { headers, body }).pipe(take(1)),
      method
    ) as Observable<T>;
  }

  usingHeaders(headers: HttpHeaders): any {
    this._tempHeaders = headers;
    return this;
  }

  get tempHeaders(): HttpHeaders {
    const tempHeaders = this._tempHeaders;
    this._tempHeaders = null;
    return tempHeaders;
  }

  // a helper function to replace v1 or v4 or v99 with an overridding version of the api you want
  // did it this way to support mixed versions in a single service class
  buildPath(method: string, version: number) {
    const path = this.endpoint() + method;
    return this.versioning ? path.replace(/(\/v)([0-9]{1,2})(\/)/g, '$1' + version + '$3') : path;
  }
}
