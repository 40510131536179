import {
  HttpHandler,
  HttpProgressEvent,
  HttpInterceptor,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpUserEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * IE11 caches GET XHR requests, this causes the problem where requrest of the same input
 * are not fetched from the API. E.g. click a checkbox, do not see change reflected in UI
 * because UI is given cached response. This is a fix to solve that, by adding the non cache
 * headers.
 */
//source https://stackoverflow.com/a/49011436
export class RestNoCacheInterceptorService implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>
  > {
    const nextReq = req.clone({
      headers: req.headers
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .set('If-Modified-Since', '0'),
    });

    return next.handle(nextReq);
  }
}
