import { PartnerUserRole } from '@pushdr/partnerportal/common/types';

export class AddPartnerUserModelWrapper {
  user: AddPartnerUserModel;
  showUserType: boolean;
}

export class AddPartnerUserModel {
  partnerId = '';
  role: PartnerUserRole = PartnerUserRole.USER;
  email = '';
  mobile = '';

  constructor(partnerId: string = '') {
    this.partnerId = partnerId;
  }
}
