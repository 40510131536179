import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import {
  UniqueIdentifierTypes,
  InvitePatientResponse,
  InvitePatientApiRequest,
  PartnerPromotion,
} from '@pushdr/common/types';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalInviteService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/Invite';
  }

  getInvites(status: number = 0) {
    const body = { status } as any;
    return this.get<InvitePatientResponse[]>('', body, this.headerService.bearerTokenHeaders(), 2);
  }

  getInviteTypes() {
    return this.get<PartnerPromotion[]>('/types', {}, this.headerService.bearerTokenHeaders(), 2);
  }

  sendInvite(invite: InvitePatientApiRequest) {
    return this.post(
      '',
      {
        promotionId: invite.promotionId,
        uniqueIdentifier: invite.dob || invite.uniqueIdentifier,
        uniqueIdentifierType: invite.uniqueIdentifierType || UniqueIdentifierTypes.DATE_OF_BIRTH,
        mobile: invite.mobileNumber,
        triageSetting: invite.triage,
      },
      this.headerService.bearerTokenHeaders(),
      2
    );
  }

  sendInviteForSlot(invite: InvitePatientApiRequest) {
    return this.post(
      '/reserved',
      {
        requestedAppointmentStartTimeGmt: invite.requestedAppointmentStartTimeGmt,
        promotionId: invite.promotionId,
        uniqueIdentifier: invite.dob || invite.uniqueIdentifier,
        uniqueIdentifierType: invite.uniqueIdentifierType || UniqueIdentifierTypes.DATE_OF_BIRTH,
        mobile: invite.mobileNumber,
        triageSetting: invite.triage,
      },
      this.headerService.bearerTokenHeaders(),
      2
    );
  }

  resendInvite(inviteId: string) {
    return this.post(
      '/resend',
      {
        Data: inviteId,
      },
      this.headerService.bearerTokenHeaders(),
      2
    );
  }

  cancelInvite(inviteId: string) {
    return this.delete(`/reserved/${inviteId}`, {}, this.headerService.bearerTokenHeaders(), 2);
  }

  getActiveInviteTypes() {
    return this.getInviteTypes().pipe(
      map(types => {
        return types.filter(type => {
          const rightNow = moment.utc(new Date().toISOString());
          const expired = moment(type.activeToISO).isBefore(rightNow);
          const inFuture = moment(type.activeFromISO).isSameOrAfter(rightNow);
          return !expired && !inFuture;
        });
      })
    );
  }
}
