import * as moment from 'moment';

export interface PartnerPromotion {
  activeFromISO?: string;
  activeToISO?: string;
  promotionDescription?: string;
  promotionName?: string;
  allowanceName?: string;
  allowance?: number;
  partnerSummaries?: PartnerPromotionSummary[];
  refreshPeriod?: number;
  remainingBalance?: number;
  temporaryIncrease?: number;
  partnerId: string;
  activeFromDate: string;
  activeToDate: string;
  applicationType: number;
  inviteExpiryMinutes: number;
  promotionId: number;
  lastRefreshDate?: string;
  refreshMinutes?: string;
  appointmentAllowanceId?: number;
}

export interface PartnerPromotionSummary {
  gpName: string;
  organisationName: string;
  partnerId: string;
  partnerName: string;
}

export interface PartnerPromotionType {
  id: number;
  name: string;
  description: string;
  start: string;
  end: string;
  promotionTypeId: number;
  code: number;
  promotionCategoryTypeId: number;
  promotionValue: number;
  clientId: number;
  promotionCustomerLength: number;
  promotionCustomerUnit: number;
  introductory: boolean;
  cardNotNeeded: boolean;
  emailTemplateId: number;
  billingDay: any;
  emailWorkflowId: any;
  promotionPaymentUnit: any;
  promotionPaymentLength: any;
  promotionPaymentAmount: any;
  promotionService: any;
}

export function generateMockPromotionType() {
  return {
    id: 99,
    name: 'MOCK_NAME',
    description: 'MOCK_DESCRIPTION',
    start: 'MOCK_START',
    end: 'MOCK_END',
    promotionTypeId: 99,
    code: 99,
    promotionCategoryTypeId: 99,
    promotionValue: 99,
    clientId: 99,
    promotionCustomerLength: 99,
    promotionCustomerUnit: 99,
    introductory: false,
    cardNotNeeded: false,
    emailTemplateId: 99,
    billingDay: null,
    emailWorkflowId: null,
    promotionPaymentUnit: null,
    promotionPaymentLength: null,
    promotionPaymentAmount: null,
    promotionService: null,
  };
}

export function generateNewPromotion(
  partnerId: string,
  promotionId: number,
  appointmentAllowanceId = 99
): PartnerPromotion {
  return {
    activeFromDate: moment.utc().format('YYYY-MM-DD'),
    activeToDate: moment.utc().format('YYYY-MM-DD'),
    applicationType: 0,
    inviteExpiryMinutes: 1440,
    temporaryIncrease: 200,
    partnerId,
    promotionId,
    appointmentAllowanceId,
  };
}
