<div class="c-modal__wrap">
  <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
    <div class="modal__body">
      <h2 class="c-modal__title">Register</h2>
      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="vemail.invalid && f.submitted">
        <label for="ipt-email">Email address</label>
        <input
          [(ngModel)]="email"
          #vemail="ngModel"
          name="email"
          class="c-form-part__field"
          id="ipt-email"
          required
          aria-required="true"
          type="text" />
        <p
          class="c-form-part__error-message"
          id="err-msg-email-required"
          *ngIf="vemail?.errors?.required">
          An email is required
        </p>
      </div>

      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="vphoneToken.invalid && f.submitted">
        <label for="ipt-phoneToken">Mobile verification code</label>
        <input
          [(ngModel)]="phoneToken"
          #vphoneToken="ngModel"
          name="phoneToken"
          class="c-form-part__field"
          id="ipt-phoneToken"
          required
          aria-required="true"
          type="text" />
        <p
          class="c-form-part__error-message"
          id="err-msg-phoneToken-required"
          *ngIf="vphoneToken?.errors?.required">
          A mobile verification code is required, it will have been sent to your mobile via SMS
        </p>
      </div>

      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="vpw.invalid && f.submitted">
        <label class="c-form-part__field-label" for="ipt-password">Set Password</label>
        <input
          [(ngModel)]="password"
          name="Password"
          #vpw="ngModel"
          required
          class="c-form-part__field"
          id="ipt-password"
          aria-required="true"
          type="password" />
        <p class="c-form-part__error-message" style="display: block">
          Passwords must contain:<br />
          - One uppercase and lowercase letter<br />
          - One number<br />
          - One special character (e.g. *%!£$)<br />
          - Minimum of {{ PASSWORD_LENGTH_MIN }} characters
        </p>
      </div>
    </div>
    <div class="modal__footer">
      <button
        class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin u-mb16@xs"
        value="Register">
        Register
      </button>
    </div>
  </form>
</div>
