import { capitalize } from 'lodash';

export enum PartnerUserRole {
  ADMIN = 1,
  USER = 2,
  MANAGER = 3,
}

export class PartnerUser {
  id = '';
  email = '';
  emailConfirmed = false;
  mobile = '';
  mobileNumberConfirmed = false;
  role: PartnerUserRole = PartnerUserRole.USER;

  constructor(user: any = null) {
    if (!user) return;
    this.id = user.id;
    this.email = user.email;
    this.emailConfirmed = user.emailConfirmed;
    this.mobile = user.mobile;
    this.mobileNumberConfirmed = user.phoneNumberConfirmed;
    this.role = user.userRole;
  }
}

export function convertRoleToUserType(role: PartnerUserRole): string {
  return capitalize(PartnerUserRole[role]);
}
