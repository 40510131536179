import { Injectable } from '@angular/core';
import storageFallback from 'local-storage-fallback';
declare const window: any;

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor() {
    this.polyfillSessionStorageIfNeeded();
  }

  polyfillSessionStorageIfNeeded() {
    try {
      window.sessionStorage.setItem('___test_store___', 1);
      window.sessionStorage.removeItem('___test_store___');
    } catch (e) {
      // localstorage and sessionstator are readonly , to override them we need to modify the proto of their base class.
      // only do this IF we cannot do the above wihch implies storage is turned off and we need to fallbackk
      // in those cases, the app will not work and this is a hailmary
      Storage.prototype.getItem = storageFallback.getItem.bind(storageFallback);
      Storage.prototype.setItem = storageFallback.setItem.bind(storageFallback);
      Storage.prototype.clear = storageFallback.clear.bind(storageFallback);
      Storage.prototype.removeItem = storageFallback.removeItem.bind(storageFallback);
    }
  }

  check(name: string): boolean {
    if (typeof document === 'undefined') return false; // Check if document exist avoiding issues on server side prerendering
    const regexp = new RegExp('(?:^' + name + '|;\\s*' + name + ')=(.*?)(?:;|$)', 'g');
    const exists = regexp.test(document.cookie);
    return exists;
  }

  get(name: string, returnAsJSON: boolean = false) {
    if (this.check(name)) {
      const regexp = new RegExp('(?:^' + name + '|;\\s*' + name + ')=(.*?)(?:;|$)', 'g');
      const result = regexp.exec(document.cookie);
      const decoded = decodeURIComponent(result[1]);
      return returnAsJSON ? JSON.parse(decoded) : decoded;
    } else {
      return '';
    }
  }

  set(
    name: string,
    value: any,
    expiresInHours: number = null,
    expiresInDays: number = null,
    expiresOn: Date = new Date(new Date().setHours(23, 59, 59, 999)),
    path: string = '/',
    domain: string = 'auto',
    secure: boolean = true,
    sameSiteNone: boolean = false
  ) {
    value = typeof value === 'string' ? value : JSON.stringify(value);
    let cookieStr = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';

    let dtExpires = expiresOn;
    if (expiresInHours) {
      dtExpires = new Date(new Date().getTime() + expiresInHours * 1000 * 60 * 60);
    } else if (expiresInDays) {
      dtExpires = new Date(new Date().getTime() + expiresInDays * 1000 * 60 * 60 * 24);
    }

    if (path) {
      cookieStr += 'path=' + path + ';';
    }
    if (domain) {
      cookieStr += 'domain=' + (domain === 'auto' ? this.domain() : domain) + ';';
    }
    if (sameSiteNone) {
      cookieStr += 'SameSite=None;';
    }
    if (secure) {
      cookieStr += 'secure;';
    }

    if (dtExpires) {
      cookieStr += 'expires=' + dtExpires.toUTCString();
    }

    // console.log(cookieStr);
    document.cookie = cookieStr;

    return cookieStr;
  }

  domain() {
    let i = 0,
      domain = document.domain;
    const p = domain.split('.'),
      s = '_gd' + new Date().getTime();
    while (i < p.length - 1 && document.cookie.indexOf(s + '=' + s) === -1) {
      domain = p.slice(-1 - ++i).join('.');
      document.cookie = s + '=' + s + ';domain=' + domain + ';';
    }
    document.cookie = s + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + domain + ';';
    return domain;
  }

  delete(name: string, path: string = '/', domain: string = 'auto') {
    this.set(name, '', -1, 0, null, path, domain);
  }

  getLocalStorage(key: string, returnAsJSON: boolean = false) {
    return returnAsJSON ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key);
  }

  setLocalStorage(key: any, objectToStore: any) {
    localStorage.setItem(key.toString(), JSON.stringify(objectToStore));
  }

  deleteLocalStorage(key?: string) {
    key ? localStorage.removeItem(key) : localStorage.clear();
  }

  getSessionStorage(key: string, returnAsJSON: boolean = false) {
    const data = sessionStorage.getItem(key);
    if (data === null || data === 'undefined') return null;
    return returnAsJSON ? JSON.parse(data) : data;
  }

  setSessionStorage(key: any, objectToStore: any) {
    sessionStorage.setItem(key.toString(), JSON.stringify(objectToStore));
  }

  deleteSessionStorage(key?: string) {
    key ? sessionStorage.removeItem(key) : sessionStorage.clear();
  }

  private falsyKey(store, key) {
    return store.getItem('key') == null || store.getItem('key') === 'NaN';
  }
}
