<div class="c-modal__wrap" [class.c-modal__wrap--loader]="loading">
  <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
    <div *ngIf="!loading" class="modal__body">
      <h2 class="c-modal__title">Password reset</h2>
      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="vemail.invalid && f.submitted">
        <label for="ipt-email">Email address</label>
        <input
          [(ngModel)]="email"
          #vemail="ngModel"
          name="email"
          class="c-form-part__field"
          id="ipt-email"
          required
          aria-required="true"
          type="text" />
        <p
          class="c-form-part__error-message"
          id="err-msg-email-required"
          *ngIf="vemail?.errors?.required">
          An email is required
        </p>
      </div>

      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="vpw.invalid && f.submitted">
        <label class="c-form-part__field-label" for="ipt-password">Set Password</label>
        <input
          [(ngModel)]="password"
          name="Password"
          #vpw="ngModel"
          required
          class="c-form-part__field"
          id="ipt-password"
          aria-required="true"
          type="password" />
        <p
          class="c-form-part__error-message"
          id="err-msg-password-required"
          *ngIf="vpw?.errors?.required">
          A password is required
        </p>
        <p class="c-form-part__error-message" style="display: block">
          Passwords must contain:<br />
          - One uppercase and lowercase letter<br />
          - One number<br />
          - One special character (e.g. *%!£$)<br />
          - Minimum of {{ PASSWORD_LENGTH_MIN }} characters
        </p>
      </div>
    </div>
    <div *ngIf="!loading" class="modal__footer">
      <button
        class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin u-mb16@xs">
        Reset password
      </button>
      <p class="u-text-center u-text-quaternary" *ngIf="error">{{ error }}</p>
    </div>
    <div *ngIf="loading">
      <div class="c-modal__loader">
        <pushdr-spinner-loader></pushdr-spinner-loader>
      </div>
    </div>
  </form>
</div>
