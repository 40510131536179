import { Component, HostBinding, OnInit } from '@angular/core';
import {
  EnvironmentProxyService,
  BrowserDetectorService,
  StorageService,
} from '@pushdr/common/utils';
import { RestErrorParserService } from '@pushdr/common/data-access/rest-http-core';
import { SignInPartnerPortalService } from '@pushdr/partnerportal/authentication';
import { PDServerError } from '@pushdr/common/types';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { ModalService } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isIE10 = false;

  @HostBinding('attr.pdr-version')
  get pdrVersion(): string {
    return this.envProxy.environment.version;
  }

  constructor(
    private modal: ModalService,
    private errors: RestErrorParserService,
    private router: Router,
    private signInService: SignInPartnerPortalService,
    private envProxy: EnvironmentProxyService,
    private analytics: AnalyticsService,
    private browserDetectorService: BrowserDetectorService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.isIE10 = this.browserDetectorService.isIE10();
    this.checkIfShouldClearTokens();
    this.analytics.initialise('partnerportal');
    this.errors.errors$.pipe(delay(1)).subscribe((error: PDServerError) => {
      switch (error.status) {
        case 401:
          if (error.url.indexOf('GetToken') === -1) {
            this.handleAuthFailure();
          }
          break;
        case 403:
          this.handleAuthFailure();
          break;
        case 0:
        case 500:
          this.modal.error(
            'Something went wrong - refresh your browser and try again, if it persists please contact your system administrator'
          );
          break;
      }
    });
  }

  private checkIfShouldClearTokens() {
    const sessionStarted = this.storage.getSessionStorage('session_started', true);
    const persistToken = this.storage.getLocalStorage('persistTokens', true);
    if (!sessionStarted && !persistToken) {
      this.signInService.signOutAll();
    }
    this.storage.setSessionStorage('session_started', true);
  }

  private handleAuthFailure() {
    this.modal
      .acknowledge(
        'Session expired',
        `Your session seems to have expired for your ${this.signInService.getUserName()} account. Please sign in again and you can continue.`,
        'Please sign in again'
      )
      .subscribe(() => {
        this.signInService.signOut();
        this.router.navigate(['login']);
      });
  }
}
