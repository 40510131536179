import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalPartnerUserService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/users/partner';
  }

  getPartnerUsers(partnerId?: string) {
    const path = partnerId ? '/' + partnerId : '';
    return this.get(path, {}, this.headerService.bearerTokenHeaders(), 3);
  }

  addPartnerManagerUser(email: string, mobile: string, partnerId?: string) {
    return this.post(
      '/' + partnerId + '/partnermanager',
      {
        email,
        mobile,
        partnerId,
      },
      this.headerService.bearerTokenHeaders(),
      3
    );
  }

  addPartnerUser(email: string, partnerId?: string) {
    let body,
      path = '';
    if (partnerId) {
      path = '/' + partnerId + '/cn';
      body = { partnerId, email };
    } else {
      body = { email };
    }
    return this.post(path, body, this.headerService.bearerTokenHeaders(), 3);
  }

  deletePartnerUser(userId: string, partnerId?: string) {
    const partnerPath = partnerId ? `/${partnerId}` : '';
    const actionPath = `/remove/${userId}`;
    const path = partnerPath + actionPath;
    return this.delete(path, {}, this.headerService.bearerTokenHeaders(), 3);
  }

  resendPartnerUserVerification(userId: string, partnerId?: string) {
    const partnerPath = partnerId ? `/${partnerId}` : '';
    const actionPath = `/resend/${userId}`;
    const path = partnerPath + actionPath;
    return this.get(path, {}, this.headerService.bearerTokenHeaders(), 3);
  }
}
