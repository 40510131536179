import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Surgery } from '@pushdr/common/types';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'pushdr-surgery-list',
  templateUrl: './surgery-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurgeryListComponent implements OnInit {
  @Input() partnerLinks: Surgery[];
  @Output() queryChange = new EventEmitter<string>();
  @Output() surgeryClicked = new EventEmitter<Surgery>();

  @ViewChild('queryInput', { static: true }) queryInput: ElementRef;

  ngOnInit() {
    fromEvent(this.queryInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(200),
        map((element: any) => element.target.value)
      )
      .subscribe((query: string) => {
        this.queryChange.emit(query);
      });
  }
}
