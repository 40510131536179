import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { OpeningDayType, PartnerServiceHours } from '@pushdr/common/types';

@Injectable({ providedIn: 'root' })
export class ApiPartnerServiceTimesService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/PartnerServiceTimes';
  }

  getTimes(partnerId?: string, dayType?: OpeningDayType) {
    const queryParams = {} as any;
    if (dayType) {
      queryParams.dayType = dayType;
    }
    if (partnerId) {
      queryParams.partnerId = partnerId;
    }
    return this.get<PartnerServiceHours[]>(
      '',
      queryParams,
      this.headerService.bearerTokenHeaders()
    );
  }

  addTime(time: PartnerServiceHours) {
    return this.post('', time, this.headerService.bearerTokenHeaders());
  }

  getTime(serviceTimeId: string) {
    return this.post<PartnerServiceHours[]>(
      `/${serviceTimeId}`,
      {},
      this.headerService.bearerTokenHeaders()
    );
  }

  updateTime(serviceTimeId: string | number, time: PartnerServiceHours) {
    return this.put(`/${serviceTimeId}`, time, this.headerService.bearerTokenHeaders());
  }

  deleteTime(serviceTimeId: string | number) {
    return this.delete(`/${serviceTimeId}`, {}, this.headerService.bearerTokenHeaders());
  }

  cloneServiceHours(partnerFrom, partnerTo) {
    const params = {
      partnerFrom,
      partnerTo,
    };
    return this.post('/CloneServiceHours', params, this.headerService.bearerTokenHeaders());
  }
}
