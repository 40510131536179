import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { PartnerUser, PartnerUserRole } from '@pushdr/partnerportal/common/types';

@Component({
  selector: 'pushdr-partner-user-list',
  templateUrl: './partner-user-list.component.html',
  styleUrls: ['./partner-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerUserListComponent {
  PartnerUserRole: typeof PartnerUserRole = PartnerUserRole;

  @Input() userRole: PartnerUserRole;
  @Input() partnerUsers: PartnerUser[];
  @Input() query: string;
  @Output() clearQuery = new EventEmitter<void>();
  @Output() resendVerification = new EventEmitter<PartnerUser>();
  @Output() addUser = new EventEmitter<PartnerUser>();
  @Output() deleteUser = new EventEmitter<PartnerUser>();
}
