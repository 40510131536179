import { Component, OnInit } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { PDServerError } from '@pushdr/common/types';
import { ApiPartnerPortalService } from '@pushdr/partnerportal/common/data-access/partnerportal-api';
import { SignInPartnerPortalService } from '../../services/sign-in/sign-in-partner-portal.service';
import { StorageService } from '@pushdr/common/utils';

@Component({
  selector: 'pushdr-surgery-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  username: string;
  password: string;
  persistToken: boolean;
  forgotPassword: false;

  constructor(
    private api: ApiPartnerPortalService,
    private modal: ModalService,
    private signIn: SignInPartnerPortalService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.persistToken = this.storage.getLocalStorage('persistTokens', true) || true;
  }

  submit() {
    this.storage.setLocalStorage('persistTokens', this.persistToken);
    this.modal.showLoader({
      bottomText: 'Verifying',
    });
    this.signIn.signIn(this.username, this.password).subscribe(
      () => {
        this.signIn.redirectBasedOnUserType().then(() => {
          this.modal.close();
        });
      },
      (error: PDServerError) => {
        switch (error.status) {
          case 401:
            this.modal.error('Invalid email address and/or password');
            break;
          default:
            this.modal.error(
              'Something went wrong, if this continues contact your network administrator'
            );
        }
      }
    );
  }

  resetPassword() {
    this.modal.showLoader({
      bottomText: 'Resetting',
    });
    this.api.account.sendPasswordReset(this.username).subscribe({
      next: message => {
        this.modal.acknowledge(message, '');
        this.forgotPassword = false;
      },
      error: () => this.modal.error('Something went wrong, please try again later.'),
    });
  }
}
