import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { PartnerUserRole } from '@pushdr/partnerportal/common/types';
import { AddPartnerUserModel, AddPartnerUserModelWrapper } from './add-partner-user-model';

@Component({
  selector: 'pushdr-add-partner-user-modal',
  templateUrl: './add-partner-user-modal.component.html',
  styleUrls: ['./add-partner-user-modal.component.scss'],
})
export class AddPartnerUserModalComponent extends SimpleModalComponent<
  AddPartnerUserModelWrapper,
  AddPartnerUserModel
> {
  user: AddPartnerUserModel;
  showUserType: boolean;
  PartnerUserRole: typeof PartnerUserRole = PartnerUserRole;
  ukMobileRegexPattern = '^(07[\\d]{8,12}|\\+447[\\d]{7,11})$';

  constructor() {
    super();
  }

  add() {
    this.result = this.user;
    this.close();
  }

  cancel() {
    this.close();
  }
}
