import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { Observable } from 'rxjs';

export interface AdminUser {
  email: string;
  id: string;
  lockOutEnd: any;
  mobile: string;
}

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalAdminUserService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/AdminUser';
  }

  // Admin User
  getUsers(): Observable<AdminUser[]> {
    return this.get<AdminUser[]>('', {}, this.headerService.bearerTokenHeaders());
  }

  deleteUser(id: string) {
    return this.delete('/' + id, {}, this.headerService.bearerTokenHeaders());
  }

  addUser(email: string, mobile: string) {
    return this.post(
      '',
      {
        email: email,
        mobile: mobile,
      },
      this.headerService.bearerTokenHeaders()
    );
  }

  resendVerification(email: string, mobile: string) {
    return this.post(
      '/ResendVerification',
      {
        email: email,
        mobile: mobile,
      },
      this.headerService.bearerTokenHeaders()
    );
  }
}
