import { Component, Input } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Component({
  selector: 'pushdr-home-logo',
  templateUrl: 'home-logo.component.html',
})
export class HomeLogoComponent {
  @Input() homeUrl: string = this.env.environment.homepage || '/';

  constructor(private env: EnvironmentProxyService) {}
}
