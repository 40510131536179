import { SurgeryType } from '@pushdr/common/types';
import { Validators } from '@angular/forms';
import { ControlTypeEnum, FormControlOption } from '@pushdr/common/overlay';
import { urlEndsWithNhsValidator } from '@pushdr/common/utils';

export enum SignpostStatusEnum {
  Draft = 0,
  Live = 1,
  Archived = 2,
}

export enum QuestionnaireType {
  Unknown = 0,
  AdultConsultation = 1,
  FitNote = 2,
  ChildConsultation = 3,
  RepeatPrescription = 4,
  Physio = 5,
  MinorAilmentsPharmacist = 6,
  FaceToFace = 7,
  MentalHealthTherapist = 8,
}

export const QuestionnaireTypesWithEmailAction = [
  QuestionnaireType.FitNote,
  QuestionnaireType.RepeatPrescription,
];

export const QuestionnaireTypesWithAction = [...QuestionnaireTypesWithEmailAction];

export interface Signpost {
  id: string;
  name: string;
  status: SignpostStatusEnum;
  questionnaireId: string;
  description: string;
  priority: number;
}

export interface PartnerSignpost extends Signpost {
  isEnabled: boolean;
  isDefault: boolean;
}

export interface Questionnaire {
  name: string;
  id: string;
  type: QuestionnaireType;
}

interface EmailAction {
  emailAction: {
    email: string;
  };
}

interface AvailabilityAction {
  availabilityAction: {
    availabilityPartnerId: string;
  };
}

export interface PartnerQuestionnaire extends EmailAction, AvailabilityAction {
  name: string;
  id: string;
  questionnaireType: QuestionnaireType;
}

export interface PartnerConfiguration extends EmailAction, AvailabilityAction {
  partner: {
    id: string;
    name: string;
    surgeryType: SurgeryType;
  };
}

export interface PartnerConfigurationSignpost extends PartnerConfiguration {
  isDefault: boolean;
  isEnabled: boolean;
}

export const SIGNPOST_NEW = 'new';

export interface SignpostListDto {
  signpostList: Signpost[];
}

export interface PartnerSignpostListDto {
  signpostList: PartnerSignpost[];
}

export interface PartnerSignpostsDto {
  partnerConfigurationList: PartnerConfigurationSignpost[];
}

export interface PartnerQuestionnaireConfigurationsDto {
  partnerConfigurationList: PartnerConfiguration[];
}

export interface PartnerQuestionnaireDto {
  questionnaireList: PartnerQuestionnaire[];
}

export const FORM_CONTROL_EMAIL_OPTIONS: FormControlOption = {
  name: 'email',
  label: 'Send to email inbox',
  formControlConfig: [null, [Validators.required, Validators.email, urlEndsWithNhsValidator]],
  type: ControlTypeEnum.TEXT,
};
