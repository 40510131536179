import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export class DeleteUserModalData {
  userType: string;
  email: string;
  emailConfirmed?: boolean;
  mobile?: string;
  mobileConfirmed?: boolean;
}

@Component({
  selector: 'pushdr-delete-partner-user-model',
  templateUrl: './delete-partner-user-model.component.html',
  styleUrls: ['./delete-partner-user-model.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class DeletePartnerUserModelComponent
  extends SimpleModalComponent<DeleteUserModalData, boolean>
  implements DeleteUserModalData
{
  userType: string;
  email: string;
  emailConfirmed: boolean;
  mobile?: string;
  mobileConfirmed?: boolean;

  closeWithResult(result: boolean) {
    this.result = result;
    this.close();
  }
}
