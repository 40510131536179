import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { Partner, PartnerServiceHours } from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({ providedIn: 'root' })
export class ApiPartnerInfoService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/PartnerInfo';
  }

  getPartnerInfo() {
    return this.get<Partner>('', {}, this.headerService.bearerTokenHeaders(), 3);
  }

  getServiceHours() {
    return this.get<PartnerServiceHours[]>(
      '/serviceHours',
      {},
      this.headerService.bearerTokenHeaders(),
      3
    );
  }
}
