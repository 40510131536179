import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericListComponent } from './generic-list/generic-list.component';
import { PartnerportalHeaderComponent } from './partnerportalheader/partnerportalheader.component';
import { SurgeryListComponent } from './surgery-list/surgery-list.component';
import { SurgeryListItemComponent } from './surgery-list-item/surgery-list-item.component';
import { RouterModule } from '@angular/router';
import { SharedLogoModule, GenericCardModule } from '@pushdr/common/components';
import { PartnerportalCommonPipesModule } from '@pushdr/partnerportal/common/pipes';
import { PartnerUserListComponent } from './partner-users/partner-user-list/partner-user-list.component';
import { AddPartnerUserModalComponent } from './partner-users/add-partner-user-modal/add-partner-user-modal.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { FormsModule } from '@angular/forms';
import { DeletePartnerUserModelComponent } from './partner-users/delete-partner-user-model/delete-partner-user-model.component';
import { A11yModule } from '@angular/cdk/a11y';
import { PartnerTitleComponent } from './partner-title/partner-title.component';
import { PartnerportalAuthenticationModule } from '@pushdr/partnerportal/authentication';

@NgModule({
  declarations: [
    GenericListComponent,
    PartnerportalHeaderComponent,
    SurgeryListComponent,
    SurgeryListItemComponent,
    PartnerUserListComponent,
    AddPartnerUserModalComponent,
    PartnerTitleComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    A11yModule,
    SharedLogoModule,
    GenericCardModule,
    PartnerportalCommonPipesModule,
    SharedLogoModule,
    PartnerportalAuthenticationModule,
    SimpleModalModule.forRoot(
      { container: document.body },
      {
        closeOnEscape: true,
        closeOnClickOutside: true,
        wrapperDefaultClasses: 'c-modal',
        bodyClass: 'js-modal-open',
        wrapperClass: 'c-modal--is-visible',
        animationDuration: 300,
        autoFocus: true,
      }
    ),
    DeletePartnerUserModelComponent,
  ],
  exports: [
    GenericListComponent,
    PartnerportalHeaderComponent,
    SurgeryListComponent,
    SurgeryListItemComponent,
    PartnerUserListComponent,
    AddPartnerUserModalComponent,
    PartnerTitleComponent,
    DeletePartnerUserModelComponent,
  ],
})
export class PartnerportalCommonComponentsModule {}
