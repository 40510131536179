import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SignInPartnerPortalService } from '../services/sign-in/sign-in-partner-portal.service';

@Injectable({
  providedIn: 'root',
})
export class IsThisUserGuard implements CanActivate {
  constructor(private user: SignInPartnerPortalService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const id = route.params.id;
    if (this.user.currentUser.id !== id) {
      const foundUser = this.user.userList.find(user => user.id === id);
      if (foundUser) {
        this.user.currentUser = foundUser;
        return true;
      } else {
        return this.router.createUrlTree(['login']);
      }
    }
    return true;
  }
}
