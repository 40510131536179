import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { ApiPartnerPortalService } from '@pushdr/partnerportal/common/data-access/partnerportal-api';
import { SignInPartnerPortalService } from '../../services/sign-in/sign-in-partner-portal.service';
import { PASSWORD_LENGTH_MIN } from '@pushdr/assets';

@Component({
  selector: 'pushdr-surgery-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  loading = false;
  email: string;
  mobile: string;
  code: string;
  password: string;
  PASSWORD_LENGTH_MIN = PASSWORD_LENGTH_MIN;

  constructor(
    private api: ApiPartnerPortalService,
    private route: ActivatedRoute,
    private router: Router,
    private modal: ModalService,
    private signIn: SignInPartnerPortalService
  ) {}

  ngOnInit() {
    if (this.signIn.currentUser) {
      this.modal.showLoader({ bottomText: 'Redirecting...' });
      this.signIn.redirectBasedOnUserType().then(() => {
        this.modal.close();
      });
    } else {
      this.getQueryParams();
    }
  }

  getQueryParams() {
    this.route.queryParams.subscribe(params => {
      this.email = params.username.replace(' ', '+');
      this.code = params.token;
    });
  }

  submit() {
    if (!this.loading) {
      this.loading = true;
      this.api.account.resetPassword(this.email, this.password, this.code).subscribe(
        () => {
          this.modal.acknowledge('Password reset', '');
          this.router.navigate(['login']);
        },
        error => {
          this.modal.error(error.message);
          this.loading = false;
        }
      );
    }
  }
}
