import { NgModule } from '@angular/core';
import { GenericCardComponent } from './generic-card.component';
import { CommonModule } from '@angular/common';
import { SpinnerLoaderModule } from '../spinner-loader/spinner-loader.module';

@NgModule({
  imports: [CommonModule, SpinnerLoaderModule],
  declarations: [GenericCardComponent],
  exports: [GenericCardComponent],
})
export class GenericCardModule {}
