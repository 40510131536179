import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { PartnerType, GenericEnum } from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalEnumsService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/enums/';
  }

  // Enums
  getEnumApplicationTypes() {
    return this.get<GenericEnum[]>('ApplicationType', {}, this.headerService.bearerTokenHeaders());
  }

  getEnumPartnerTypes() {
    return this.get<typeof PartnerType>('PartnerType', {}, this.headerService.bearerTokenHeaders());
  }

  getEnumDataTypeIdentifier() {
    return this.get('DataTypeIdentifier', {}, this.headerService.bearerTokenHeaders());
  }

  getInvitationStatuses() {
    return this.get('PromotionInvitationStatus', {}, this.headerService.bearerTokenHeaders());
  }
}
