import { Component, OnInit } from '@angular/core';
import { TokenService } from '@pushdr/common/utils';
import { SignInPartnerPortalService, SignInUser } from '@pushdr/partnerportal/authentication';
import { PartnerPortalPartnerType, PartnerUserRole } from '@pushdr/partnerportal/common/types';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'pushdr-partnerportal-header',
  templateUrl: './partnerportalheader.component.html',
  styleUrls: ['./partnerportalheader.component.scss'],
})
export class PartnerportalHeaderComponent implements OnInit {
  PartnerPortalPartnerType: typeof PartnerPortalPartnerType = PartnerPortalPartnerType;
  PartnerUserRole: typeof PartnerUserRole = PartnerUserRole;
  user$: Observable<SignInUser>;

  constructor(private token: TokenService, private signIn: SignInPartnerPortalService) {}

  ngOnInit() {
    this.user$ = this.signIn.user$;
  }
}
