import {
  Allowance,
  AllowancePartnerPromotions,
  ClinicianType,
  InviteSetting,
  ListPartner,
  Partner,
  Surgery,
  SurgeryType,
  TriageSetting,
} from '../partner';
import { PartnerType } from '../nhs';
import * as moment from 'moment';

export const partnerListStub: ListPartner = {
  id: 'MOCK_ID',
  organisationID: 1,
  organisationName: 'MOCK_ORG_NAME',
  surgeryType: 'MOCK_SURGERY_TYPE',
  gpName: 'MOCK_GP_NAME',
  name: 'MOCK_NAME',
  isLive: false,
  partnerType: PartnerType.NHS,
  clinicianType: ClinicianType.GeneralPractitioner,
  activePromotion: true,
};

export const partnerStub: Partner = {
  id: 'MOCK_ID',
  name: 'MOCK_NAME',
  partnerType: 2,
  userAccountDomain: 'MOCK_DOMAIN',
  todayOnly: true,
  isLive: false,
  liveDate: 'MOCK_LIVE_DATE',
  liveSurgeryTypeId: SurgeryType.PRIVATE,
  translatorService: {
    isEnabled: true,
    accessCode: '12345',
    phoneNumber: '01234512311',
  },
  triageSetting: TriageSetting.TRIAGE_OFF,
  organisation: {
    id: 999,
    organisationName: 'MOCK_ORG_NAME',
  },
  gp: {
    id: 999,
    name: 'MOCK_GP_NAME',
    address1: 'MOCK_ADD_1',
    address2: 'MOCK_ADD_2',
    address3: 'MOCK_ADD_3',
    address4: 'MOCK_ADD_4',
    postCode: 'MOCK_POSTCODE',
    odsCode: 'MOCK_ODSCODE',
    surgeryType: 'MOCK_SURGERY_TYPE',
    surgeryTypeId: 1,
    numberOfPatients: 99,
  },
  appointmentAllowances: [
    {
      id: 99,
      promotionId: 99,
      partnerId: 'MOCK_PARTNER_ID',
      name: 'MOCK_ALLOWANCE_NAME',
    },
  ],
  appointmentInviteType: InviteSetting.NO_INVITE_REQUIRED,
  accessQuestionnaireEmailAddress: '',
  consultationRecording: false,
  isMultipleAppointmentEnabled: false,
};

export const surgeryStub: Surgery = {
  add1: 'MOCK_ADD1',
  add2: 'MOCK_ADD1',
  add3: 'MOCK_ADD1',
  add4: 'MOCK_ADD1',
  id: 99,
  lat: 99,
  linkedCustomers: 1,
  lon: 99,
  name: 'MOCK_NAME',
  odscode: 'MOCK_ODSCODE',
  partnershipType: 1,
  postCode: 'MOCK_POST_CODE',
  promotionClientId: 99,
  surgeryTypeId: 4,
  tel: 'MOCK_TEL',
};

export const allowanceStub: Allowance = {
  id: 99,
  name: 'MOCK_NAME',
  allowance: 99,
  refreshMinutes: 1440,
  temporaryIncrease: 200,
  lastRefreshDate: moment('2021-04-01T13:09:58').toISOString(),
  nextRefreshDate: moment('2021-04-01T13:09:58').add(1440, 'minutes').toISOString(),
  usedThisPeriod: 99,
  partnerPromotions: [
    {
      partnerName: 'MOCK_PARTNER_NAME',
      partnerId: '99',
      promotionId: 99,
      promotionName: 'MOCK_PROMO_NAME',
      appointmentAllowanceId: 99,
      temporaryIncrease: 200,
    },
    {
      partnerName: 'MOCK_PARTNER_NAME_2',
      partnerId: '100',
      promotionId: 100,
      promotionName: 'MOCK_PROMO_NAME_2',
      appointmentAllowanceId: 100,
      temporaryIncrease: 200,
    },
  ],
};
