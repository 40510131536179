import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { PartnerPromotionType } from '@pushdr/common/types';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalPromotionsService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/promotions';
  }

  getPromotions() {
    return this.get<PartnerPromotionType[]>('', {}, this.headerService.bearerTokenHeaders(), 2);
  }
}
