import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalAccountService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/account';
  }

  // Account

  getUserInfo() {
    return this.get('', {}, this.headerService.bearerTokenHeaders());
  }

  getAccount() {
    return this.get('', {}, this.headerService.bearerTokenHeaders(), 3);
  }

  getToken(email: string, password: string) {
    return this.post(
      '/GetToken',
      {
        email: email,
        password: password,
      },
      this.headerService.bearerTokenHeaders()
    );
  }

  verifyEmail(email: string, password: string, token: string) {
    return this.post(
      '/VerifyEmail',
      {
        email: email,
        token: token,
        password: password,
      },
      this.headerService.bearerTokenHeaders()
    );
  }

  verifyMobile(email: string, mobile: string, code: string, password: string) {
    return this.post(
      '/VerifyMobile',
      {
        mobile: mobile,
        email: email,
        token: code,
        password: password,
      },
      this.headerService.bearerTokenHeaders()
    );
  }

  verifyManager(email: string, emailToken: string, phoneToken: string, password: string) {
    return this.put(
      '/verify/manager',
      {
        email,
        emailToken,
        phoneToken,
        password,
      },
      this.headerService.bearerTokenHeaders(),
      3
    );
  }

  VerifyPartnerUser(email: string, password: string, emailToken: string) {
    return this.put(
      '/verify/partneruser',
      {
        email,
        password,
        emailToken,
      },
      this.headerService.bearerTokenHeaders(),
      3
    );
  }

  sendPasswordReset(email: string) {
    return this.get(
      '/GetResetPasswordLink',
      {
        username: email,
      },
      this.headerService.bearerTokenHeaders()
    );
  }

  resetPassword(email: string, newPassword: string, token: string) {
    return this.post('/ResetPassword', {
      email: email,
      password: newPassword,
      token: token,
    });
  }
}
