<div class="c-card" [ngStyle]="customStyles">
  <div class="c-card__body">
    <div class="o-row o-row--no-margin-bottom">
      <div class="col-xs-12 col-md-3" *ngIf="title">
        <p class="u-font-weight-normal">{{ title }}</p>
      </div>
      <div
        [ngClass]="{ 'col-xs-12 col-md-9': title, 'col-xs-12 col-md-12': !title }"
        *ngIf="hasData; else loading">
        <ng-content></ng-content>
      </div>
      <ng-template #loading>
        <div [ngClass]="{ 'col-xs-12 col-md-9': title, 'col-xs-12 col-md-12': !title }">
          <pushdr-spinner-loader [minheight]="0"></pushdr-spinner-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
