import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { ExtendedWindow, StorageService, TokenService, WINDOW } from '@pushdr/common/utils';
import { ApiPartnerPortalService } from '@pushdr/partnerportal/common/data-access/partnerportal-api';
import { PartnerPortalPartnerType, PartnerUserRole } from '@pushdr/partnerportal/common/types';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

export interface SignInUser {
  id: string;
  bearerToken?: string;
  tokenExpiry?: number;
  name?: string;
  canBookPatientAppointments: boolean;
  partnerType: PartnerPortalPartnerType;
  role: PartnerUserRole;
}

@Injectable({
  providedIn: 'root',
})
export class SignInPartnerPortalService {
  private _user$ = new BehaviorSubject<SignInUser>(this.currentUser);
  private _userList$ = new BehaviorSubject<SignInUser[]>(this.userList);

  readonly TOKEN_EXPIRY = 24 * 60 * 60 * 1000;

  constructor(
    private storage: StorageService,
    private token: TokenService,
    private api: ApiPartnerPortalService,
    private modal: ModalService,
    private router: Router,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {
    this.purgeExpiredUsers();
  }

  get user$() {
    return this._user$.asObservable();
  }

  get userList$() {
    return this._userList$.asObservable();
  }

  get currentUser(): SignInUser {
    let user = null;
    try {
      user = this.storage.get('User', true);
    } catch (e) {
      this.storage.delete('User');
    }
    if (!user) {
      user = this.currentUser = this.userList.length ? this.userList[0] : null;
    }
    return user;
  }

  set currentUser(user: SignInUser) {
    if (user) {
      this.storage.set('User', user);
      this.token.set(user.bearerToken, null, null, new Date(user.tokenExpiry));
      this._user$.next(user);
    }
  }

  get userList(): SignInUser[] {
    return (this.storage.get('UserList', true) as any[]) || [];
  }

  signIn(username: string, password: string) {
    return this.api.account.getToken(username, password).pipe(
      switchMap(bearerToken => this.getUserAccountInfo(bearerToken)),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getUserAccountInfo(bearerToken: string) {
    this.token.set(bearerToken, null, null, new Date(Date.now() + this.TOKEN_EXPIRY));
    return this.api.account.getAccount().pipe(
      tap(response => {
        const user: SignInUser = Object.assign({}, response);
        user.bearerToken = bearerToken;
        user.tokenExpiry = Date.now() + this.TOKEN_EXPIRY;
        this.addToUserList(user);
        this.currentUser = user;
      }),
      catchError(err => {
        this._user$.error(err);
        return of(err);
      })
    );
  }

  signOut() {
    this.removeFromUserList(this.currentUser);
    this.signoutCommon();
  }

  signOutAll() {
    this.deleteUserList();
    this.signoutCommon();
  }

  getUserName(user?: SignInUser, showRole = true) {
    const theUser = user || this.currentUser;
    if (!theUser) {
      return '';
    }
    switch (theUser.role) {
      case PartnerUserRole.ADMIN:
        return 'Administrator';
      case PartnerUserRole.MANAGER:
        return (theUser.name || 'Loading...') + (showRole ? ' (Manage)' : '');
      case PartnerUserRole.USER:
        return (theUser.name || 'Loading...') + (showRole ? ' (Invite)' : '');
    }
  }

  redirectBasedOnUserType() {
    const user = this.currentUser;
    if (!user) {
      return this.router.navigate(['login']);
    }
    const role: PartnerUserRole = user.role;
    const partnerType: PartnerPortalPartnerType = user.partnerType;
    let routingPromise: Promise<boolean> = new Promise(() => {});

    switch (role) {
      case PartnerUserRole.ADMIN:
        routingPromise = this.router.navigate(['portal', 'admin', user.id]);
        break;
      case PartnerUserRole.MANAGER:
        this.window.location.href = '/portal/manager/' + user.id;
        break;
      case PartnerUserRole.USER:
        switch (partnerType) {
          case PartnerPortalPartnerType.NHS:
            this.window.location.href = '/portal/nhs/' + user.id;
            break;
          case PartnerPortalPartnerType.CORP:
            console.warn('Deprecated corperate users are no longer supported!');
            routingPromise = this.router.navigate(['portal', 'unknown']);
            break;
          default:
            routingPromise = this.router.navigate(['portal', 'unknown']);
        }
        break;
      default:
        routingPromise = this.router.navigate(['portal', 'unknown']);
    }

    return routingPromise;
  }

  setCurrentUserName(name: string) {
    const user = this.currentUser;
    user.name = name;
    this.addToUserList(user);
    this.currentUser = user;
  }

  private deleteCurrentUser() {
    this.storage.delete('User');
    this._user$.next(this.currentUser);
  }

  private addToUserList(user: SignInUser) {
    const userList = this.userList.filter(u => u.id !== user.id);
    userList.push(user);
    this.storage.set('UserList', userList);
    this._userList$.next(userList);
  }

  private removeFromUserList(user: SignInUser) {
    let userList = this.userList;
    userList = userList.filter(u => u.id !== user.id);
    this.storage.set('UserList', userList);
    this._userList$.next(userList);
  }

  private deleteUserList() {
    this.storage.delete('UserList');
    this._userList$.next([]);
  }

  private signoutCommon() {
    this.token.delete();
    this.deleteCurrentUser();
    this.modal.close();
  }

  private purgeExpiredUsers() {
    const userListCopy = this.userList;
    userListCopy.forEach(user => {
      if (Date.now() > user.tokenExpiry) {
        this.removeFromUserList(user);
        if (user.id === this.currentUser.id) {
          this.deleteCurrentUser();
        }
      }
    });
    if (!userListCopy.length && this.currentUser) {
      this.deleteCurrentUser();
    }
  }
}
