/** Compare two similar arrays. If a value of a key at the same index of each array is different then return true.
 Or if the objects are different lengths then something changed */
export function hasArrayValueChanged<T>(key: string, prev: T[], next: T[]) {
  const prevArr = prev.map(val => val[key]);
  const nextArr = next.map(val => val[key]);
  return (
    prevArr.length === nextArr.length &&
    prevArr.every((element, index) => element === nextArr[index])
  );
}
