import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './routes/sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './routes/reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { VerifyAdminComponent } from './routes/verify-admin/verify-admin.component';
import { VerifyUserComponent } from './routes/verify-user/verify-user.component';
import { VerifyManagerComponent } from './routes/verify-manager/verify-manager.component';
import { CommonComponentsModule } from '@pushdr/common/components';
import { TrimOnBlurModule } from '@pushdr/common/utils';
import { UserSelectorComponent } from './components/user-selector/user-selector.component';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import { QuestionnaireAcknowledgementComponent } from './routes/questionnaire-acknowledgement/questionnaire-acknowledgement.component';
import { AcknowledgeQuestionnaireComponent } from './routes/acknowledge-questionnaire/acknowledge-questionnaire.component';

@NgModule({
  declarations: [
    SignInComponent,
    ResetPasswordComponent,
    VerifyAdminComponent,
    VerifyManagerComponent,
    VerifyUserComponent,
    UserSelectorComponent,
    QuestionnaireAcknowledgementComponent,
    AcknowledgeQuestionnaireComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonComponentsModule,
    TrimOnBlurModule,
    CommonOverlayModule,
  ],
  exports: [
    SignInComponent,
    ResetPasswordComponent,
    VerifyAdminComponent,
    VerifyUserComponent,
    UserSelectorComponent,
    QuestionnaireAcknowledgementComponent,
    AcknowledgeQuestionnaireComponent,
  ],
})
export class PartnerportalAuthenticationModule {}
