import { Inject, Injectable, Optional } from '@angular/core';
import { ExtendedWindow, WINDOW } from '../../utils/window/window.service';
import { StorageService } from '../storage/storage.service';
import { TokenServiceConfig, TokenServiceOptions } from './token-config';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private tokenName = '';
  private domain = 'auto';

  constructor(
    private store: StorageService,
    @Optional() @Inject(WINDOW) private window?: ExtendedWindow,
    @Optional() @Inject(TokenServiceOptions) config?: TokenServiceConfig
  ) {
    if (config) {
      this.configure(config.tokenName, config.useHostName);
    }
  }

  configure(name: string, useHostName?: boolean) {
    this.tokenName = name;
    this.domain = useHostName ? this.window.location.hostname : 'auto';
    return this;
  }

  exists() {
    return !!this.store.get(this.tokenName);
  }

  set(
    bearerToken: string,
    expiryHours: number = null,
    expiryDays: number = null,
    expiresOn: Date = new Date(new Date().setHours(23, 59, 59, 999))
  ) {
    if (expiresOn) {
      return this.store.set(this.tokenName, bearerToken, null, null, expiresOn, '/', this.domain);
    }
    this.store.set(this.tokenName, bearerToken, expiryHours, expiryDays, null, '/', this.domain);
  }

  delete() {
    this.store.delete(this.tokenName);
  }

  get() {
    return this.store.get(this.tokenName);
  }
}
