import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({ providedIn: 'root' })
export class ApiWebHooksService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.webHooksAPI;
  }

  markAccessQuestionnaireAsRead(reference: string, hash: string) {
    return this.post(
      '/AccessQuestionnaire',
      {
        Reference: reference,
        Hash: hash,
      },
      this.headerService.unauthorisedHeaders()
    );
  }

  markQuestionnaireAsRead(reference: string, hash: string) {
    return this.post(
      '/CustomerQuestionnaireAudit/Acknowledge',
      {
        Reference: reference,
        Hash: hash,
      },
      this.headerService.unauthorisedHeaders()
    );
  }
}
