import { convertToParamMap, Params } from '@angular/router';
import { of, Subject } from 'rxjs';

export const activatedRouteStub = {
  snapshot: {
    queryParams: of({ with: '', allowanceid: 99, partnerid: 'MOCK_PARTNER_ID' }),
    params: of({ id: '0' }),
    data: {},
    paramMap: {
      get: () => {},
    },
    queryParamMap: { get: () => {} },
  },
  queryParams: of({ with: '', allowanceid: 99, partnerid: 'MOCK_PARTNER_ID' }),
  params: of({ id: '0' }),
};

/**
 * An ActivateRoute test double with a `paramMap` observable.
 * Use the `setParamMap()` method to add the next `paramMap` value.
 */
export class ActivatedRouteStub {
  private _params: Params;
  private _data: any;

  constructor(initialParams: Params = {}, data: any = {}) {
    this.setParams(initialParams);
    this.setData(data);
  }

  get paramMap() {
    return of(convertToParamMap(this._params));
  }
  get params() {
    return of(this._params);
  }
  get queryParams() {
    return of(this._params);
  }

  get snapshot() {
    return {
      queryParams: this.params,
      params: this.params,
      paramMap: this.paramMap,
      data: this._data,
    };
  }

  /** Set the paramMap observables's next value */
  setParams(params?: Params) {
    this._params = params;
  }

  setData(data: any) {
    this._data = data;
  }
}

export const patientAnalyticsServiceStub = {
  brazeInitialized$: new Subject(),
  fireBaseInitialized$: new Subject(),
  initialise: () => {},
  trackEvent: () => {},
  setUser: () => {},
};

export const routerStub = {
  navigate: () => ({}),
  events: of({}),
  getCurrentNavigation: () => {
    return {
      trigger: 'NaviagationEnd',
      extras: {
        state: {},
      },
    };
  },
  createUrlTree: () => {
    return '';
  },
};
