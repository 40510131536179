import { Pipe, PipeTransform } from '@angular/core';
import { SurgeryType } from '@pushdr/common/types';

@Pipe({
  name: 'surgeryType',
  pure: true,
})
export class SurgeryTypePipe implements PipeTransform {
  transform(type: SurgeryType, ...args: any[]): string {
    switch (type) {
      case SurgeryType.EMIS:
        return 'NHS EMIS';
      case SurgeryType.TPP:
        return 'NHS TPP';
      case SurgeryType.PRIVATE:
        return 'Private';
      case SurgeryType.HYBRID:
        return 'Hybrid';
    }
  }
}
