import { TriageSetting } from '../partner/partner';
import { DobComposite } from '../common';

export interface InvitePatientForm {
  dob?: DobComposite;
  triage?: boolean;
  econsult?: string;
  promotionId?: number;
  mobileNumber?: string;
  appointmentStartTime?: string;
}

export interface InvitePatientApiRequest {
  promotionId: number;
  dob?: string;
  triage?: TriageSetting;
  mobileNumber?: string;
  uniqueIdentifier?: string;
  uniqueIdentifierType?: UniqueIdentifierTypes;
  requestedAppointmentStartTimeGmt?: string;
}

export interface InvitePatientResponse {
  id: number;
  mobile: string;
  code: string;
  link: string;
  triageSetting: TriageSetting;
  uniqueIdentifier: string;
  uniqueIdentifierType: UniqueIdentifierTypes;
  partnerPromotionPromotionId: number;
  partnerPromotionPartnerId: string;
  inviteStatus: number;
  registeredCustomerId: any;
}

export enum UniqueIdentifierTypes {
  DATE_OF_BIRTH = 0,
  EMAIL_ADDRESS = 1,
}
