<h4>Search for and select a GP link</h4>
<div class="c-search-input__input_wrap">
  <input
    #queryInput
    type="search"
    name="query"
    class="c-search-input__field"
    placeholder="Search (ODS, Name)" />
</div>

<div *ngIf="partnerLinks?.length; else noresults">
  <pushdr-surgery-list-item
    *ngFor="let surgery of partnerLinks"
    [surgery]="surgery"
    (click)="surgeryClicked.emit(surgery)"></pushdr-surgery-list-item>
</div>

<ng-template #noresults>
  <h3 style="text-align: center">
    <i class="search-icon material-icons"> search </i>&nbsp;Sorry nothing matched your search term
  </h3>
</ng-template>
