import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { Observable } from 'rxjs';
import {
  SignpostListDto,
  Questionnaire,
  Signpost,
  SignpostStatusEnum,
  PartnerQuestionnaireConfigurationsDto,
  PartnerSignpostsDto,
} from '@pushdr/partnerportal/common/types';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
// The service is called `common` since the URL has no special postfix
export class ApiPartnerPortalCommonService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api;
  }

  getQuestionnaires(): Observable<{ questionnaireList: Questionnaire[] }> {
    return this.get(`/questionnaire`, {}, this.headerService.bearerTokenHeaders(), 4);
  }

  getQuestionnairesIdToNameMap(): Observable<Map<Questionnaire['id'], Questionnaire['name']>> {
    return this.getQuestionnaires().pipe(
      map(data => data.questionnaireList),
      map((arr: Questionnaire[]) => {
        const idNameMap = new Map<Questionnaire['id'], Questionnaire['name']>([]);
        arr.forEach(item => idNameMap.set(item.id, item.name));
        return idNameMap;
      })
    );
  }

  getDefaultSignposts(): Observable<SignpostListDto> {
    return this.get(`/signpost`, {}, this.headerService.bearerTokenHeaders(), 4);
  }

  getDefaultSignpost(signpostId: Signpost['id']): Observable<Signpost> {
    return this.get(`/signpost/${signpostId}`, {}, this.headerService.bearerTokenHeaders(), 4);
  }

  createDefaultSignpost(data: Partial<Signpost>): Observable<{ id: Signpost['id'] }> {
    return this.post(`/signpost`, data, this.headerService.bearerTokenHeaders(), 4);
  }

  updateDefaultSignpost(
    signpostId: Signpost['id'],
    data: Partial<Signpost>
  ): Observable<{ id: Signpost['id'] }> {
    return this.put(`/signpost/${signpostId}`, data, this.headerService.bearerTokenHeaders(), 4);
  }

  deleteDefaultSignpostById(signpostId: Signpost['id'], data: Partial<Signpost>): Observable<any> {
    return this.updateDefaultSignpost(signpostId, {
      ...data,
      status: SignpostStatusEnum.Archived,
    });
  }

  getPartnerSignpostConfiguration(signpostId: Signpost['id']): Observable<PartnerSignpostsDto> {
    return this.get(
      `/signpost/${signpostId}/partnerConfiguration`,
      {},
      this.headerService.bearerTokenHeaders(),
      4
    );
  }

  getPartnerQuestionnaireConfiguration(
    questionnaireId: Questionnaire['id']
  ): Observable<PartnerQuestionnaireConfigurationsDto> {
    return this.get(
      `/questionnaire/${questionnaireId}/partnerConfiguration`,
      {},
      this.headerService.bearerTokenHeaders(),
      4
    ).pipe(
      map((data: PartnerQuestionnaireConfigurationsDto) => {
        data.partnerConfigurationList.forEach(item => {
          item.emailAction = item.emailAction ? item.emailAction : { email: null };
          item.availabilityAction = item.availabilityAction
            ? item.availabilityAction
            : { availabilityPartnerId: null };
        });
        return data;
      })
    );
  }
}
