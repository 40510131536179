import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiWebHooksService } from '@pushdr/common/data-access/web-hooks';
import { switchMap, take } from 'rxjs/operators';

enum QuestionnaireStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

@Component({
  selector: 'pushdr-track',
  templateUrl: './questionnaire-acknowledgement.component.html',
})
export class QuestionnaireAcknowledgementComponent implements OnInit {
  status: QuestionnaireStatus;
  message = '';

  constructor(private route: ActivatedRoute, private api: ApiWebHooksService) {}

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap(params => this.api.markAccessQuestionnaireAsRead(params.reference, params.hash)),
        take(1)
      )
      .subscribe({
        next: () => (this.status = QuestionnaireStatus.SUCCESS),
        error: err => {
          //TODO: Error handling will be updated to different types of errors once api has implemented CQRS
          this.message = err.message;
          this.status = QuestionnaireStatus.ERROR;
        },
      });
  }
}
