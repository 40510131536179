<pushdr-generic-card [hasData]="partnerUsers">
  <table class="pp-table" *ngIf="partnerUsers?.length">
    <thead>
      <th>Email</th>
      <th class="u-text-center">Verified</th>
      <th class="u-text-center">Manager</th>
      <th></th>
    </thead>
    <tbody>
      <tr *ngFor="let user of partnerUsers">
        <td>{{ user.email }}</td>
        <ng-container
          *ngIf="
            user.emailConfirmed || user.mobileNumberConfirmed;
            then check;
            else cross
          "></ng-container>
        <ng-container
          *ngIf="user.role === PartnerUserRole.MANAGER; then check; else cross"></ng-container>
        <td class="u-text-right">
          <button
            *ngIf="!user.emailConfirmed"
            type="button"
            title="Resend verification"
            (click)="resendVerification.emit(user)"
            class="c-btn c-btn--secondary c-btn--tiny u-ml8@xs">
            <i class="material-icons">email</i>
          </button>
          <button
            *ngIf="user.role !== userRole"
            type="button"
            title="Delete user"
            (click)="deleteUser.emit(user)"
            class="c-btn c-btn--danger c-btn--tiny u-ml8@xs">
            <i class="material-icons">delete</i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-container *ngIf="!partnerUsers?.length">
    <div *ngIf="query?.length">
      <h4>
        No users found search with:
        <pre>"{{ query }}"</pre>
      </h4>
      <button type="button" class="c-btn c-btn--primary c-btn--small" (click)="clearQuery.emit()">
        Reset search
      </button>
    </div>
    <div *ngIf="!query?.length">
      <h4 class="">No users added</h4>
      <button type="button" class="c-btn c-btn--primary c-btn--small" (click)="addUser.emit()">
        Add user
      </button>
    </div>
  </ng-container>
</pushdr-generic-card>

<ng-template #check>
  <td class="u-text-center">
    <i class="material-icons check">check_circle</i>
  </td>
</ng-template>

<ng-template #cross>
  <td class="u-text-center">
    <i class="material-icons cross">highlight_off</i>
  </td>
</ng-template>
