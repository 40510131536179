import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { AppointmentSlot, Availability } from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { combineLatest, of, Observable } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalAvailabilityService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/availability';
  }

  getAvailableAppointmentSlots() {
    return this.get<{ availability: AppointmentSlot[] }>(
      '/',
      {},
      this.headerService.bearerTokenHeaders(),
      2
    );
  }

  getPercentageAvailability(day: string): Observable<Availability[]> {
    const queryParams = {
      dateTime: moment(day, 'DD-MM-YYYY').add(12, 'hours').toISOString(),
    } as any;
    return this.get('/percentage', queryParams, this.headerService.bearerTokenHeaders(), 3.5).pipe(
      map(res => res?.slots)
    );
  }

  getAvailableAppointmentSlotsOverNext(days: number = 7) {
    const today = moment();
    const dateStrings = Array.from({ length: days }).map((day, index) => {
      return today.clone().add(index, 'day').format('YYYY-MM-DD');
    });
    const callsArray$ = dateStrings.map(YYYYMMDD =>
      this.get<{ availabilityOptions: any[] }>(
        '/' + YYYYMMDD,
        {},
        this.headerService.bearerTokenHeaders(),
        3
      ).pipe(
        catchError(() => of({ availabilityOptions: [] as any[] })),
        map(result =>
          result.availabilityOptions.map(slot => ({
            ...slot,
            startTime:
              moment(slot.startUtc + 'Z')
                .local()
                .format('YYYY-MM-DDTHH:mm:ss') || slot.startTime,
            endTime:
              moment(slot.endUtc + 'Z')
                .local()
                .format('YYYY-MM-DDTHH:mm:ss') || slot.endTime,
          }))
        )
      )
    );

    return combineLatest(callsArray$).pipe(
      map((daySlots: AppointmentSlot[][]) => {
        return { availability: [].concat(...daySlots) as AppointmentSlot[] };
      })
    );
  }
}
