<div *ngIf="currentUser$ | async as currentUser" class="relative inline-block">
  <button
    class="block text-greypp border border-solid border-grey-800 font-medium inline-block cursor-pointer bg-white p-3 rounded hover:bg-grey-100 max-w-xs truncate"
    (focus)="open = true"
    (click)="selectUser()"
    (mouseenter)="open = true">
    <span class="material-icons align-text-bottom">{{ userIcon(currentUser) }}</span>
    {{ userLabel(currentUser, false) }}
  </button>
  <div
    [class.hidden]="!open"
    class="bg-white rounded-lg shadow mt-2 absolute right-0 max-content z-30"
    style="top: 48px">
    <ng-container *ngIf="otherUsers$ | async as otherusers">
      <span
        *ngIf="otherusers.length"
        class="text-center text-greypp block w-full font-semibold bg-bluegrey-300 py-2 rounded-t-lg">
        Accounts
      </span>
      <button
        class="block px-4 py-2 hover:bg-bluegrey-100 text-greypp w-full text-left"
        *ngFor="let user of otherusers"
        (click)="selectUser(user)">
        <span class="material-icons align-text-bottom">{{ userIcon(user) }}</span>
        {{ userLabel(user) }}
        <span class="u-sr-only">select to account drop down menu</span>
      </button>
      <div
        [class.border-t]="otherusers.length"
        [class.mt-2]="otherusers.length"
        class="footer border-solid border-bluegrey-100 pt-2">
        <button
          class="block px-4 py-2 hover:bg-bluegrey-100 text-greypp w-full text-left"
          (click)="addAnother()">
          <span class="material-icons align-text-bottom">add_box</span>
          Add another account
        </button>

        <button
          class="block px-4 py-2 hover:bg-bluegrey-100 text-greypp w-full mb-2 text-left"
          (click)="signOutAll()">
          <span class="material-icons align-text-bottom">power_settings_new</span>
          Sign out of all accounts
        </button>
      </div>
    </ng-container>
  </div>
</div>
