export interface PatientBooking {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  bookingStartTime: string;
  status: BookingStatusTypes;
}

export enum BookingStatusTypes {
  BOOKED,
  CANCELLED,
  MISSED,
  COMPLETED,
}
