import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { PartnerServiceTimeException } from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({ providedIn: 'root' })
export class ApiPartnerServiceTimeExceptionsService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/PartnerServiceExceptionTimes';
  }

  getExceptionTimes(partnerId?: string) {
    const queryParams = {} as any;
    if (partnerId) {
      queryParams.partnerId = partnerId;
    }
    return this.get<PartnerServiceTimeException[]>(
      '',
      queryParams,
      this.headerService.bearerTokenHeaders()
    );
  }

  /**
   * Retrieve the list of times for a given day based upon logic of days of week, bank hols and exceptions
   * @param partnerId uuid of partner
   * @param dayString format dd-MM-yyyy
   */
  getPartnerServiceByDate(partnerId: string, dayString: string) {
    const queryParams = { partnerId, dayString } as any;
    return this.get<PartnerServiceTimeException[]>(
      '/PartnerServiceByDate',
      queryParams,
      this.headerService.bearerTokenHeaders()
    );
  }

  addExceptionTime(time: PartnerServiceTimeException) {
    return this.post('', time, this.headerService.bearerTokenHeaders());
  }

  getExceptionTime(exceptionServiceTimeId: string | number) {
    return this.post<PartnerServiceTimeException[]>(
      `/${exceptionServiceTimeId}`,
      {},
      this.headerService.bearerTokenHeaders()
    );
  }

  updateExceptionTime(exceptionServiceTimeId: string | number, time: PartnerServiceTimeException) {
    return this.put(`/${exceptionServiceTimeId}`, time, this.headerService.bearerTokenHeaders());
  }

  deleteExceptionTime(exceptionServiceTimeId: string | number) {
    return this.delete(`/${exceptionServiceTimeId}`, {}, this.headerService.bearerTokenHeaders());
  }
}
