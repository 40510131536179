export interface InviteBookingCount {
  None: number;
  Scheduled: number;
  InProgress: number;
  Completed: number;
  Cancelled: number;
  Missed: number;
}

export const InviteBookingCountDefault: InviteBookingCount = {
  None: 0,
  Scheduled: 0,
  InProgress: 0,
  Completed: 0,
  Cancelled: 0,
  Missed: 0,
};
