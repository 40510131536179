import { of } from 'rxjs';
import { testing } from '@pushdr/common/utils';
// expand these Stubs as required

export const ModalServiceStub = {
  showLoader: () => of({}),
  confirm: () => of({}),
  success: () => of({}),
  acknowledge: () => of({}),
  close: () => of({}),
  error: () => of({}),
};

export const environmentProxyServiceStub = {
  environment: testing,
};
export const tokenServiceStub = {
  delete: () => ({}),
  get: () => '',
  set: () => '',
  exists: () => true,
};
export const apiPartnerPortalServiceStub = {
  availability: {
    getPercentageAvailability: () => of([]),
    getAvailableAppointmentSlots: () => of({ availability: [] }),
    getAvailableAppointmentSlotsOverNext: () => of({ availability: [] }),
  },
  partner: {
    addPartner: () => of({}),
    getPartner: () => of({}),
    getPartnerLinks: () => of({}),
    getPartners: () => of({}),
    deletePartner: () => of({}),
    updatePartner: () => of(''),
    addCorporatePartner: () => of({}),
    addNHSPartner: () => of({}),
    updateNHSPartner: () => of(''),
    clearCustomers: () => of(''),
    setPartnerLive: () => of(''),
    getPartnerPromotions: () => of([]),
    addPartnerPromotion: () => of(''),
    editPartnerPromotion: () => of(''),
    enablePartnerSignpost: () => of(''),
    disablePartnerSignpost: () => of(''),
    getPartnerSpecificSignposts: () => of(''),
    createPartnerQuestionnaireEmailAction: () => of(true),
    updatePartnerQuestionnaireEmailAction: () => of(true),
    createPartnerQuestionnaireAvailabilityAction: () => of({}),
    updatePartnerQuestionnaireAvailabilityAction: () => of({}),
    getRelations: () => of([]),
    updateProperty: () => of({}),
  },
  organisation: {
    getAll: () => of([]),
    get: () => of([]),
    create: () => of([]),
    edit: () => of([]),
  },
  allowance: {
    getAllowance: () => of({}),
    putAllowance: () => of({}),
    createAllowance: () => of({}),
    allowanceAddNhsPartner: () => of({}),
    getAllowances: () => of({}),
  },
  promotions: {
    getPromotions: () => of([]),
  },
  enums: {
    getEnumPartnerTypes: () => of({}),
    getEnumApplicationTypes: () => of([]),
  },
  invite: {
    sendInvite: () => of(''),
    sendInviteForSlot: () => of(''),
    getInvites: () => of([]),
    getActiveInviteTypes: () => of([]),
  },
  info: {
    getPartnerInfo: () => of({}),
    getServiceHours: () => of([]),
  },
  booking: {
    getBookings: () => of([]),
    getTotalPartnerBookingCountsToday: () => of([]),
    getTotalPartnerBookingCountsTomorrowOnwards: () => of([]),
  },
  common: {
    getQuestionnaires: () => of([]),
    getQuestionnairesIdToNameMap: () => of([]),
    getDefaultSignposts: () => of([]),
    getDefaultSignpost: () => of([]),
    createDefaultSignpost: () => of([]),
    updateDefaultSignpost: () => of([]),
    deleteDefaultSignpostById: () => of([]),
    getPartnerSignpostConfiguration: () => of([]),
    getPartnerQuestionnaireConfiguration: () => of([]),
  },
};
export const signInPartnerPortalServiceStub = {
  currentUser: { id: 99 },
  user$: of({ partnerType: 1 }),
  userList$: of([{ partnerType: 1 }]),
  signIn: () => of(true),
  signOut: () => {},
  getUserAccountInfo: () => of(true),
  getUserName: () => '',
  setCurrentUserName: () => {},
  redirectBasedOnUserType: () => Promise.resolve(true),
};

export const locationStub = {
  go: () => '',
  back: () => '',
  path: () => '',
};
export const featureToggleServiceStub = {
  get: () => {},
  isActive: () => true,
  track: () => ({}),
  configuration: () => ({}),
};
export const systemCheckServiceStub = {
  isCompatible: true,
  isBrowserCompatible: true,
  isWindows: true,
  browser: '',
  OS: '',
  userAgent: '',
};
export const documentStub = {
  location: {
    href: 'test',
  },
  hasFocus: () => {},
  querySelectorAll: () => {},
};

const mockNotification = function () {
  return this;
};
mockNotification.permission = 'default';
mockNotification.requestPermission = () => new Promise(() => mockNotification.permission);

export const windowStub = {
  scrollTo: () => {},
  location: {
    href: '',
    pathname: '',
    origin: '',
    reload: () => {},
  },
  open: () => {},
  Notification: mockNotification,
  AudioContext: function () {
    this.createAnalyser = function () {
      this.fftSize = 0;
      this.frequencyBinCount = 0;
      this.getByteTimeDomainData = () => {};
      return this;
    };
    this.createMediaStreamSource = function () {
      this.connect = () => {};
      return this;
    };
    return this;
  },
  webkitAudioContext: function () {
    return this;
  },
  Intercom: () => {},
  confirm: () => {},
  console: {
    log: () => {},
  },
  sessionStorage: {
    setItem: () => {},
    removeItem: () => {},
  },
  URL: {
    createObjectURL: () => {},
  },
  gapi: {
    auth2: {
      getAuthInstance: function () {
        return Promise.resolve({
          grantOfflineAccess: a => {
            return { code: 'fake-token' };
          },
        });
      },
    },
  },
  FB: {
    login: res => ({ authResponse: true }),
  },
  history: {
    replaceState() {
      return true;
    },
  },
};
export const headerServiceStub = {
  menuIsOpen: false,
  toggleBurgerMenu: () => ({}),
  closeHeaderMenu: () => ({}),
  openHeaderMenu: () => ({}),
};
export const browserDetectorServiceStub = {
  isIE10: () => ({}),
};
