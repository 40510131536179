import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import {
  CreatePartnerNHS,
  ListPartner,
  Partner,
  PartnerEpsDetails,
  Promotion,
  Surgery,
  UpdatePartnerNHS,
  PartnerRelationList,
  PartnerPage,
} from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import {
  PartnerQuestionnaireDto,
  PartnerSignpostListDto,
} from '@pushdr/partnerportal/common/types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalPartnerService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/partner';
  }

  getPartners(): Observable<ListPartner[]> {
    return this.get<ListPartner[]>('', {}, this.headerService.bearerTokenHeaders());
  }

  getPartnersWithPaging(args: {
    activePromotion?: boolean;
    organisationId?: number;
    name?: string;
    pageNumber: number;
    pageSize?: number;
  }): Observable<PartnerPage> {
    return this.post(
      '/withPaging',
      {
        ActivePromotion: args.activePromotion,
        OrganisationId: args.organisationId,
        NameSearchText: args.name,
        PageNumber: args.pageNumber,
        PageSize: args.pageSize,
      },
      this.headerService.bearerTokenHeaders()
    );
  }

  addNHSPartner(data: CreatePartnerNHS) {
    // TODO

    return this.post('/nhs', data, this.headerService.bearerTokenHeaders(), 2);
  }

  updateNHSPartner(data: UpdatePartnerNHS) {
    // TODO

    return this.put('/nhs', data, this.headerService.bearerTokenHeaders(), 2);
  }

  getPartner(id: string) {
    return this.get<Partner>('/' + id, {}, this.headerService.bearerTokenHeaders(), 2);
  }

  deletePartner(id: string) {
    return this.delete('/' + id, {}, this.headerService.bearerTokenHeaders());
  }

  setPartnerLive(id: string) {
    return this.put(`/${id}/setlive`, {}, this.headerService.bearerTokenHeaders(), 2);
  }

  clearCustomers(id: string) {
    return this.put(`/${id}/clearcustomers`, {}, this.headerService.bearerTokenHeaders(), 2);
  }

  getPartnerLinks(search: string) {
    return this.get<Surgery[]>('/links', { search }, this.headerService.bearerTokenHeaders());
  }

  getPartnerPromotions(partnerId: string) {
    return this.get('/' + partnerId + '/promotion', {}, this.headerService.bearerTokenHeaders(), 2);
  }

  addPartnerPromotion(promotion: Promotion) {
    return this.post(
      '/' + promotion.partnerId + '/promotion',
      {
        applicationType: promotion.applicationType,
        inviteExpiryMinutes: promotion.inviteExpiryMinutes,
        activeFromISO: promotion.activeFromISO,
        promotionId: promotion.promotionId,
        partnerId: promotion.partnerId,
        refreshPeriod: promotion.refreshPeriod,
        allowance: promotion.allowance,
      },
      this.headerService.bearerTokenHeaders(),
      2
    );
  }

  editPartnerPromotion(promotion: Promotion) {
    return this.put(
      '/' + promotion.partnerId + '/promotion',
      {
        applicationType: promotion.applicationType,
        inviteExpiryMinutes: promotion.inviteExpiryMinutes,
        promotionId: promotion.promotionId,
        partnerId: promotion.partnerId,
        activeToISO: promotion.activeToISO,
        activeFromISO: promotion.activeFromISO,
        refreshPeriod: promotion.refreshPeriod,
        allowance: promotion.allowance,
        temporaryIncrease: promotion.temporaryIncrease,
      },
      this.headerService.bearerTokenHeaders(),
      2
    );
  }

  uploadCSVOfCorporateUsers(partnerId: string, promotionId: string, data: string) {
    return this.post(
      '/' + partnerId + '/promotion/' + promotionId + '/upload',
      {
        data: data,
      },
      this.headerService.bearerTokenHeaders(),
      2
    );
  }

  enablePartnerSignpost(partnerId: string, signpostId: string) {
    return this.post(
      `/${partnerId}/signpost/${signpostId}/enable`,
      null,
      this.headerService.bearerTokenHeaders(),
      4
    );
  }

  disablePartnerSignpost(partnerId: string, signpostId: string) {
    return this.post(
      `/${partnerId}/signpost/${signpostId}/disable`,
      null,
      this.headerService.bearerTokenHeaders(),
      4
    );
  }

  getPartnerSpecificSignposts(partnerId: string): Observable<PartnerSignpostListDto> {
    return this.get(`/${partnerId}/signpost`, {}, this.headerService.bearerTokenHeaders(), 4);
  }

  getPartnerQuestionnaires(partnerId: string): Observable<PartnerQuestionnaireDto> {
    return this.get(
      `/${partnerId}/questionnaire`,
      {},
      this.headerService.bearerTokenHeaders(),
      4
    ).pipe(
      map((data: PartnerQuestionnaireDto) => {
        data.questionnaireList.forEach(item => ({
          ...item,
          emailAction: item.emailAction ? item.emailAction : { emailAction: { email: null } },
          availabilityAction: item.availabilityAction
            ? item.availabilityAction
            : { availabilityAction: { availabilityPartnerId: null } },
        }));
        return data;
      })
    );
  }

  getPartnerEpsDetails(partnerId: string): Observable<PartnerEpsDetails> {
    return this.get<PartnerEpsDetails>(
      `/${partnerId}/eps-details`,
      {},
      this.headerService.bearerTokenHeaders(),
      4
    );
  }

  createEpsDetails(epsDetails: PartnerEpsDetails) {
    return this.post(
      `/${epsDetails.partnerId}/eps-details`,
      epsDetails,
      this.headerService.bearerTokenHeaders(),
      4
    );
  }

  updateEpsDetails(epsDetails: PartnerEpsDetails) {
    return this.put(
      `/${epsDetails.partnerId}/eps-details`,
      epsDetails,
      this.headerService.bearerTokenHeaders(),
      4
    );
  }

  createPartnerQuestionnaireEmailAction(
    partnerId: string,
    questionnaireId: string,
    email: string
  ): Observable<any> {
    return this.post(
      `/${partnerId}/questionnaire/${questionnaireId}/emailAction`,
      {
        email,
      },
      this.headerService.bearerTokenHeaders(),
      4
    );
  }

  updatePartnerQuestionnaireEmailAction(
    partnerId: string,
    questionnaireId: string,
    email: string
  ): Observable<any> {
    return this.put(
      `/${partnerId}/questionnaire/${questionnaireId}/emailAction`,
      {
        email,
      },
      this.headerService.bearerTokenHeaders(),
      4
    );
  }
}
