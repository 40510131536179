import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputDobModule } from './input-dob/input-dob.module';
import { SpinnerLoaderModule } from './spinner-loader/spinner-loader.module';
import { AlertBarModule } from './alert-bar/alert-bar.module';
import { SharedLogoModule } from './home-logo/shared-logo.module';
import { DatePickerModule } from './date-picker/date-picker.module';
import { FormComponentsModule } from './form-components/form-components.module';
import { NotFoundModule } from './not-found/not-found.module';
import { ModalsModule } from './modals/modals.module';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputDobModule,
    SpinnerLoaderModule,
    AlertBarModule,
    SharedLogoModule,
    DatePickerModule,
    FormComponentsModule,
    NotFoundModule,
    ModalsModule,
  ],
  exports: [
    InputDobModule,
    SpinnerLoaderModule,
    AlertBarModule,
    SharedLogoModule,
    DatePickerModule,
    FormComponentsModule,
    NotFoundModule,
    ModalsModule,
  ],
})
export class CommonComponentsModule {}
