import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PASSWORD_LENGTH_MIN } from '@pushdr/assets';
import { ModalService } from '@pushdr/common/overlay';
import { ApiPartnerPortalService } from '@pushdr/partnerportal/common/data-access/partnerportal-api';
import { switchMap } from 'rxjs/operators';
import { SignInPartnerPortalService } from '../../services/sign-in/sign-in-partner-portal.service';

@Component({
  selector: 'pushdr-verify-manager',
  templateUrl: './verify-manager.component.html',
  styleUrls: ['./verify-manager.component.scss'],
})
export class VerifyManagerComponent implements OnInit {
  email: string;
  phoneToken: string;
  emailToken: string;
  password: string;
  PASSWORD_LENGTH_MIN = PASSWORD_LENGTH_MIN;

  constructor(
    private api: ApiPartnerPortalService,
    private route: ActivatedRoute,
    private modal: ModalService,
    private user: SignInPartnerPortalService
  ) {}

  ngOnInit() {
    this.getQueryParams();
  }

  getQueryParams() {
    this.route.queryParams.subscribe(params => {
      this.email = params.username.replace(' ', '+');
      this.emailToken = params.token;
    });
  }

  submit() {
    this.modal.showLoader({ bottomText: 'Registering' });
    this.api.account
      .verifyManager(this.email, this.emailToken, this.phoneToken, this.password)
      .pipe(switchMap(() => this.user.signIn(this.email, this.password)))
      .subscribe(
        () => {
          this.user.redirectBasedOnUserType();
          this.modal.close();
        },
        error => {
          this.modal.error(error.message);
        }
      );
  }
}
