<pushdr-alert-bar *ngIf="isIE10" type="warning" dismissable="true" top="true">
  <strong>Improve your experience!</strong> You are using an outdated browser; please upgrade to a
  newer browser for a better experience on PushDoctor. Here are the instructions on how to
  <strong><a href="http://browsehappy.com/" target="_blank">upgrade your web browser</a></strong
  >.
</pushdr-alert-bar>

<pushdr-partnerportal-header></pushdr-partnerportal-header>
<div
  class="o-wrapper o-container o-container--fluid"
  style="min-height: 600px; padding-bottom: 100px">
  <div class="col-xs-12">
    <router-outlet></router-outlet>
  </div>
</div>
