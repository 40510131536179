import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({ providedIn: 'root' })
export class ApiPartnerPortalPartnerPatientsService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.partner.api + '/partnerpatients';
  }

  // Corporate patients
  getCorporatePatients(searchFilter: string = '') {
    return this.get('?search=' + searchFilter, {}, this.headerService.bearerTokenHeaders());
  }

  deleteCorporatePatient(id: string) {
    return this.delete('/' + id, {}, this.headerService.bearerTokenHeaders());
  }
}
