<ng-container *ngIf="status$ | async as status">
  <div class="flex justify-center w-full h-64 text-bluegrey-800">
    <div class="w-full max-w-sm p-6 m-auto text-center" [ngSwitch]="status">
      <div *ngSwitchCase="'ERROR'">
        <ng-container *ngTemplateOutlet="error"></ng-container>
      </div>
      <div *ngSwitchCase="'SUCCESS'">
        <ng-container *ngTemplateOutlet="success"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #success>
  <div class="flex justify-center w-full mb-6">
    <img class="block" alt="Green check mark" src="assets/icons/check_mark_circle_green.svg" />
  </div>
  <div class="mb-6 text-2xl">Thank you for confirming you have received this request</div>
  <div class="font-extralight">If there are any issues please contact the patient directly.</div>
</ng-template>

<ng-template #error>
  <div class="flex justify-center w-full mb-6">
    <img class="block" alt="Red error cross" src="assets/icons/cross_circle_red.svg" />
  </div>
  <div class="mb-6 text-2xl">{{ message }}</div>
</ng-template>
