import { Environment } from './utils/environment-interface';

// prettier-ignore
export const environment: Environment = {
  id: 'stub',
  version: '',
  webHooksAPI: '',
  homepage: '',
  partner: {
    api: '',
    admin: '',
    cn: ''
  },
  portal: {
    api: '',
    url: '',
    signin: '',
    apiWithJWT: '',
  },
  pdrportal: {
    url: ''
  },
  production: false,
  appInsights: {
    config: {
      instrumentationKey: 'MOCK_KEY'
    }
  },
  google: {
    portalGtm: '',
  },
};
