export enum PartnerType {
  None = 0,
  NHS = 1,
  CORPORATE = 2,
  PDR = 3,
}

export enum ActivePromotion {
  All = 0,
  Active = 1,
  Inactive = 2,
}
